import {
  search,
  get,
  put,
  post,
  remove as httpDelete,
} from '../../helpers/api';

export const SERVICE_URI = '/taxonomies';
export const BASE_URI = '/v1/constants';

export const findAll = (group) => (data) =>
  search(`${SERVICE_URI}${BASE_URI}/${group}`, data);

export const findOne = (group) => (id) =>
  get(`${SERVICE_URI}${BASE_URI}/${group}/${id}`);

export const create = (group) => (entity) =>
  post(`${SERVICE_URI}${BASE_URI}/${group}`, entity);

export const update = (group) => (entity) =>
  put(`${SERVICE_URI}${BASE_URI}/${group}/${entity.id}`, entity);

export const order = (group) => (payload) =>
  put(`${SERVICE_URI}${BASE_URI}/${group}?order`, payload);

export const remove = (group) => (entity) =>
  httpDelete(`${SERVICE_URI}${BASE_URI}/${group}/${entity.id}`);
