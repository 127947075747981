import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Space, Row, Col, Input, Select, InputNumber } from 'antd';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';

import { CONSTANTS } from './constants';
import useFormErrors from '../../../hooks/useFormErrors';
import LocalDatePicker from '../../LocalDatePicker';

const EditConstantForm = ({ constant, group, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  useFormErrors(form, errors);

  useEffect(() => {
    form.setFieldsValue(constant);
  }, [constant, form]);

  const { values, expressions, comments } = useMemo(
    () => CONSTANTS[group],
    [group],
  );

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={constant}
        onFinish={onSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={10} lg={8} xl={8}>
            <Form.Item label={t('entity.admin.constant.name')} name="name">
              <Input />
            </Form.Item>

            <Form.Item
              label={t(`entity.admin.constant.groups.${group}.expression`)}
              name="expression"
            >
              {Array.isArray(expressions) && expressions.length > 0 ? (
                <Select style={{ width: '100%' }}>
                  {expressions.map((expression) => (
                    <Select.Option value={expression} key={expression}>
                      {expression}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input />
              )}
            </Form.Item>

            {values >= 1 && (
              <Form.Item
                label={t(`entity.admin.constant.groups.${group}.value1`)}
                name="value1"
              >
                <InputNumber precision={4} />
              </Form.Item>
            )}

            {values >= 2 && (
              <Form.Item
                label={t(`entity.admin.constant.groups.${group}.value2`)}
                name="value2"
              >
                <InputNumber precision={4} />
              </Form.Item>
            )}

            {values >= 3 && (
              <Form.Item
                label={t(`entity.admin.constant.groups.${group}.value3`)}
                name="value3"
              >
                <InputNumber precision={4} />
              </Form.Item>
            )}

            {values >= 4 && (
              <Form.Item
                label={t(`entity.admin.constant.groups.${group}.value4`)}
                name="value4"
              >
                <InputNumber precision={4} />
              </Form.Item>
            )}

            <Form.Item
              label={t('entity.admin.constant.validAt')}
              name="validAt"
            >
              <LocalDatePicker />
            </Form.Item>
            {constant.name === 'MESSAGE_AFTER_LOGIN' && (
              <Form.Item
                label={t('entity.admin.constant.validTo')}
                name="validTo"
              >
                <LocalDatePicker />
              </Form.Item>
            )}
          </Col>
          <Col xs={24} sm={24} md={14} lg={16} xl={16}>
            {comments}
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <SaveButton />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditConstantForm;
