import React from 'react';

import { Default } from '../../../layouts';

import { useEditPage } from '../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../services/admin/users/subdivisions';

import EditSubdivisionForm from '../../../components/admin/users/EditSubdivisionForm';

const initial = {};

const EditSubdivision = () => {
  const [subdivision, handleSubmit, handleCancel, errors] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/users',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {subdivision !== undefined && (
        <EditSubdivisionForm
          subdivision={subdivision}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </Default>
  );
};

export default EditSubdivision;
