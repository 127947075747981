import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import { Default } from '../../layouts';
import CountriesList from '../../components/admin/geo/CountriesList';
import CountiesList from '../../components/admin/geo/CountiesList';
import CitiesList from '../../components/admin/geo/CitiesList';

const Geo = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <Tabs defaultActiveKey="countries">
        <Tabs.TabPane tab={t('entity.admin.country._plural')} key="countries">
          <CountriesList />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('entity.admin.county._plural')} key="cities">
          <CountiesList />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('entity.admin.city._plural')} key="localities">
          <CitiesList />
        </Tabs.TabPane>
      </Tabs>
    </Default>
  );
};

export default Geo;
