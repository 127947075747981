import React from 'react';

import { useTranslation } from 'react-i18next';

import { Tooltip, Typography } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';

const ViewItemIcon = ({ path }) => {
  const { t } = useTranslation();

  return (
    <Tooltip placement="left" title={t('actions.calc')}>
      <Typography.Link href={path}>
        <CalculatorOutlined />
      </Typography.Link>
    </Tooltip>
  );
};

export default ViewItemIcon;
