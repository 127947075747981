import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

const ErrorTab = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader title={t('entity.east.permit.status.CANCELLED')} />
      <Alert
        message={t('entity.east.permit.status.CANCELLED')}
        description={t('entity.east.permit.cancelMessage')}
        type="error"
      />
    </>
  );
};

export default ErrorTab;
