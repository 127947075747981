import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import { Default } from '../../layouts';

import ConstantsList from '../../components/admin/system/ConstantsList';
import { CONSTANTS } from '../../components/admin/system/constants';

const groups = Object.keys(CONSTANTS);

const System = () => {
  const { t } = useTranslation();

  const currentTab = useMemo(
    () =>
      window.location.hash ? window.location.hash.substring(1) : groups[0],
    [],
  );

  const handleTabChange = useCallback((tab) => {
    window.location.hash = `#${tab}`;
  }, []);

  return (
    <Default>
      <Tabs defaultActiveKey={currentTab} onChange={handleTabChange}>
        {groups.map((group) => (
          <Tabs.TabPane
            tab={t(`entity.admin.constant.groups.${group}.title`)}
            key={group}
          >
            <ConstantsList group={group} values={CONSTANTS[group].values} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Default>
  );
};

export default System;
