import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Divider, Row, Col, Input } from 'antd';

import BooleanSelect from '../../../BooleanSelect';
import VehicleTypeSelect from '../../../VehicleTypeSelect';
import FileInput from '../../../FileInput';

const VehicleConfigurationTab = ({ ident }) => {
  const replaceFileUrlViaBackend = useCallback(
    (str, res) =>
      str.replace(
        `/files/v1/${res.id}`,
        `/backend/v1/permits/view/${ident}/attachments/${res.id}`,
      ),
    [ident],
  );

  const { t } = useTranslation();
  return (
    <>
      <Divider plain>{t('entity.east.permit.vehicleConfiguration')}</Divider>
      <Form.Item
        name="vehicleConfiguration"
        label={t('entity.east.permit.vehicleConfiguration')}
      >
        <Input readOnly />
      </Form.Item>
      <Form.Item name="vehicleType" label={t('entity.east.permit.vehicleType')}>
        <VehicleTypeSelect open={false} />
      </Form.Item>
      <Form.Item
        name="vehiclePlateNumber"
        label={t('entity.east.permit.vehiclePlateNumber')}
      >
        <Input style={{ textTransform: 'uppercase' }} readOnly />
      </Form.Item>
      <Form.Item name="vehicleIdentityCard">
        <FileInput
          scope="vhidc"
          label={t('entity.east.permit.vehicleIdentityCard')}
          disabled
          onFileUrlSet={replaceFileUrlViaBackend}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => prev.vehicleType !== next.vehicleType}
      >
        {({ getFieldValue }) => (
          <>
            {getFieldValue('vehicleType') === 'SEMITRAILER' && (
              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item
                    name="trailerPlateNumber"
                    label={t('entity.east.permit.trailerPlateNumber')}
                  >
                    <Input readOnly style={{ textTransform: 'uppercase' }} />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {getFieldValue('vehicleType') === 'TRAILER' && (
              <Form.Item
                name="trailerPlateNumber"
                label={t('entity.east.permit.trailerPlateNumber')}
              >
                <Input readOnly style={{ textTransform: 'uppercase' }} />
              </Form.Item>
            )}

            {(getFieldValue('vehicleType') === 'TRAILER' ||
              getFieldValue('vehicleType') === 'SEMITRAILER') && (
              <Form.Item name="trailerIdentityCard">
                <FileInput
                  scope="stidc"
                  label={t('entity.east.permit.trailerIdentityCard')}
                  disabled
                  onFileUrlSet={replaceFileUrlViaBackend}
                />
              </Form.Item>
            )}
          </>
        )}
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="optionRefrigerator" label={t('Vehicul Frigorific')}>
            <BooleanSelect open={false} />
          </Form.Item>
          <Form.Item
            name="optionSpareTrailer"
            label={t('entity.east.permit.optionSpareTrailer')}
          >
            <BooleanSelect open={false} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="optionContainer"
            label={t('entity.east.permit.optionContainer')}
          >
            <BooleanSelect open={false} />
          </Form.Item>
          <Form.Item
            name="optionArticulated"
            label={t('entity.east.permit.optionArticulated')}
          >
            <BooleanSelect open={false} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} justify="space-between">
        <Col span={12}>
          <Form.Item
            name="optionAltFuel"
            label={t('entity.east.permit.optionAltFuel')}
          >
            <BooleanSelect open={false} />
          </Form.Item>
          <Form.Item
            name="optionExceed"
            label={t('entity.east.permit.optionExceed')}
          >
            <BooleanSelect open={false} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="optionPassenger"
            label={t('entity.east.permit.optionPassenger')}
          >
            <BooleanSelect open={false} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default VehicleConfigurationTab;
