import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OriginsList from '../../components/admin/origins/OriginsList';

import { Default } from '../../layouts';

const Origins = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <Tabs defaultActiveKey="operators">
        <Tabs.TabPane tab={t('entity.admin.origin._plural')} key="origins">
          <OriginsList />
        </Tabs.TabPane>
      </Tabs>
    </Default>
  );
};

export default Origins;
