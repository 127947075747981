import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';

import { useRouteParams } from '../../hooks/useRouteParams';
import { SERVICE_URI, BASE_URI } from '../../services/east/permits';

const PaymentButton = () => {
  const { t } = useTranslation();

  const { id } = useRouteParams();

  const handleSubmit = useCallback(() => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/${id}/pay`;

    document.body.appendChild(form);

    form.submit();
  }, [id]);

  return (
    <Button
      icon={<RightCircleOutlined />}
      type="primary"
      htmlType="button"
      onClick={handleSubmit}
    >
      {t('actions.payment')}
    </Button>
  );
};

export default PaymentButton;
