import React from 'react';
import AccessKeysList from '../../../components/admin/access/AccessKeysList';

import { Default } from '../../../layouts';

const AccessKeys = () => {
  return (
    <Default>
      <AccessKeysList />
    </Default>
  );
};

export default AccessKeys;
