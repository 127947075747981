import React from 'react';

import { Default } from '../../../layouts';

import { useEditPage } from '../../../hooks/useEditPage';

import {
  findOne,
  create,
  update,
} from '../../../services/admin/origins/origins';

import EditOriginForm from '../../../components/admin/origins/EditOriginForm';

const initial = {};

const EditOrigin = () => {
  const [origin, handleSubmit, handleCancel, errors] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/origins',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {origin !== undefined && (
        <EditOriginForm
          origin={origin}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </Default>
  );
};

export default EditOrigin;
