import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Divider, Input } from 'antd';

const IssuedDocSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Divider plain>{t('entity.east.permit.serialNumber')}</Divider>
      <Form.Item name="serialNumber">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="amount" label={t('entity.east.permit.totalFare')}>
        <Input readOnly />
      </Form.Item>
      <Form.Item
        name="payementType"
        label={t('entity.east.permit.paymentMethod')}
      >
        <Input readOnly />
      </Form.Item>
    </>
  );
};
export default IssuedDocSection;
