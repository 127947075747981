import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { AuthContextWrapper, PrivateRoute } from './components/auth';

import {
  Login,
  Register,
  ResetPassword,
  NotFound,
  Users,
  EditRole,
  EditUser,
  Geo,
  Origins,
  System,
  EditConstant,
  Permits,
  EditPermit,
  ViewPermit,
  EditUserInfo,
  TermsAndConditions,
  Reports,
  EditSubdivision,
  AccessKeys,
  EditCountry,
  EditCounty,
  EditCity,
  EditOrigin,
} from './pages';

const App = () => (
  <>
    <AuthContextWrapper>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />

          <Route exact path="/register" component={Register} />
          <Route exact path="/terms" component={TermsAndConditions} />

          <Route exact path="/reset" component={ResetPassword} />
          <Route exact path="/reset/:token" component={ResetPassword} />

          <PrivateRoute exact path="/home" component={Permits} />

          <PrivateRoute
            exact
            path="/admin/users"
            component={Users}
            permissions={['SYS_SUPERUSER', 'CNAIR_CONTROL']}
          />

          <PrivateRoute
            exact
            path="/admin/users/roles/:id"
            component={EditRole}
            permissions={['SYS_SUPERUSER']}
          />

          <PrivateRoute
            exact
            path="/admin/users/subdivisions/:id"
            component={EditSubdivision}
            permissions={['SYS_SUPERUSER']}
          />

          <PrivateRoute
            exact
            path="/admin/users/users/:id"
            component={EditUser}
            permissions={['SYS_SUPERUSER', 'CNAIR_CONTROL']}
          />

          <PrivateRoute
            exact
            path="/admin/geo"
            component={Geo}
            permissions={['SYS_SUPERUSER']}
          />

          <PrivateRoute
            exact
            path="/admin/geo/countries/:id"
            component={EditCountry}
            permissions={['SYS_SUPERUSER']}
          />
          <PrivateRoute
            exact
            path="/admin/geo/counties/:id"
            component={EditCounty}
            permissions={['SYS_SUPERUSER']}
          />
          <PrivateRoute
            exact
            path="/admin/geo/cities/:id"
            component={EditCity}
            permissions={['SYS_SUPERUSER']}
          />
          <PrivateRoute
            exact
            path="/admin/origins/:id"
            component={EditOrigin}
            permissions={['SYS_SUPERUSER']}
          />
          <PrivateRoute
            exact
            path="/admin/origins"
            component={Origins}
            permissions={['SYS_SUPERUSER']}
          />

          <PrivateRoute
            exact
            path="/admin/system"
            component={System}
            permissions={['SYS_SUPERUSER']}
          />

          <PrivateRoute
            exact
            path="/admin/system/constants/:group/:id"
            component={EditConstant}
            permissions={['SYS_SUPERUSER']}
          />

          <PrivateRoute
            exact
            path="/admin/access"
            component={AccessKeys}
            permissions={['SYS_SUPERUSER']}
          />

          <PrivateRoute exact path="/personal-info" component={EditUserInfo} />

          <Route exact path="/east/view/:ident" component={ViewPermit} />

          <PrivateRoute exact path="/east" component={Permits} />
          <PrivateRoute exact path="/east/:id" component={EditPermit} />

          <PrivateRoute
            exact
            path="/reports"
            component={Reports}
            permissions={['SYS_SUPERUSER', 'CNAIR_CONTROL']}
          />

          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </AuthContextWrapper>
    {window._env_.VERSION && (
      <div
        style={{
          position: 'fixed',
          bottom: '0',
          right: '0',
          opacity: '0.2',
          padding: '0 12px',
          margin: '4px',
          background: 'black',
          color: 'white',
          fontSize: '10px',
          zIndex: '65535',
        }}
      >
        {new Date(
          (parseInt(window._env_.VERSION, 10) || 0) * 1000,
        ).toISOString()}
      </div>
    )}
  </>
);

export default App;
