import React from 'react';

import { useTranslation } from 'react-i18next';

import { Tooltip, Typography } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';

const ViewItemIcon = ({ path }) => {
  const { t } = useTranslation();

  return (
    <Tooltip placement="left" title={t('actions.ast')}>
      <Typography.Link href={path}>
        <FileDoneOutlined />
      </Typography.Link>
    </Tooltip>
  );
};

export default ViewItemIcon;
