import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Form,
  Modal,
  Table,
  Input,
  Descriptions,
  Popconfirm,
  notification,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { DeleteOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';

import useDatasource from '../../../hooks/useDatasource';

import {
  findAll,
  create,
  remove,
  generateNewKey,
} from '../../../services/admin/access/access';

import Column from '../../../helpers/Columns';
import useFormErrors from '../../../hooks/useFormErrors';

const AccessKeysList = () => {
  const { t } = useTranslation();

  const { loading, pagination, content, handleChange } = useDatasource(findAll);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();
  const [errors, setErrors] = useState(null);
  useFormErrors(form, errors);

  useEffect(() => {
    form.resetFields();
  }, [form, isModalVisible]);

  const handleCreateAccessKey = useCallback(
    (value) => {
      setErrors(null);
      create(value)
        .then((res) => {
          setIsModalVisible(false);
          Modal.info({
            title: t('entity.admin.accessKey._singular'),
            width: 1000,
            content: (
              <Descriptions
                title={t('entity.admin.accessKey._singular')}
                bordered
                column={1}
              >
                <Descriptions.Item
                  key="accessKey"
                  label={t('entity.admin.accessKey.accessKey')}
                >
                  {res.accessKey}
                </Descriptions.Item>
                <Descriptions.Item
                  key="secretKey"
                  label={t('entity.admin.accessKey.secretKey')}
                >
                  {res.secretKey}
                </Descriptions.Item>
              </Descriptions>
            ),
            onOk() {},
          });
        })
        .catch((msg) => {
          const { inner } = msg || {};
          setErrors(inner);
        });
    },
    [t],
  );

  const handleGenerateNewKey = useCallback(
    (record) => {
      generateNewKey(record)
        .then((res) => {
          setIsModalVisible(false);
          Modal.info({
            title: t('entity.admin.accessKey._singular'),
            width: 1000,
            content: (
              <Descriptions
                title={t('entity.admin.accessKey._singular')}
                bordered
                column={1}
              >
                <Descriptions.Item
                  key="secretKey"
                  label={t('entity.admin.accessKey.secretKey')}
                >
                  {res.secretKey}
                </Descriptions.Item>
              </Descriptions>
            ),
            onOk() {},
          });
        })
        .catch((msg) => {
          console.error(msg);
          notification.error({
            message: `Eroare la incarcarea generarea unei chei noi: ${msg}`,
          });
        });
    },
    [t],
  );

  const columns = useMemo(
    () => [
      Column.text('entity', t('entity.admin.accessKey.entity'), {
        width: 120,
        filter: true,
      }),
      Column.text('accessKey', t('entity.admin.accessKey.accessKey'), {
        width: 200,
        filter: true,
      }),
      Column.date('createdAt', t('entity._.createdAt'), {
        width: 250,
      }),
      Column.text('createdBy', t('entity._.createdBy'), {
        width: 150,
      }),
      Column.date('updatedAt', t('entity._.updatedAt'), {
        width: 250,
      }),
      Column.text('updatedBy', t('entity._.updatedBy'), {
        width: 150,
      }),
      Column.bool('deleted', t('entity._.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(
        t('table.actions'),
        (record) => (
          <span style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
            <Popconfirm
              title={t('entity.admin.accessKey._generate')}
              onConfirm={() => handleGenerateNewKey(record)}
              okText={t('entity._.true')}
              cancelText={t('entity._.false')}
            >
              <Button shape="circle" type="link" icon={<RedoOutlined />} />
            </Popconfirm>
            <Popconfirm
              title={t('entity.admin.accessKey._delete')}
              onConfirm={() => remove(record)}
              okText={t('entity._.true')}
              cancelText={t('entity._.false')}
            >
              <Button shape="circle" type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          </span>
        ),
        { width: 80 },
      ),
    ],
    [t, handleGenerateNewKey],
  );

  return (
    <>
      <PageHeader
        title={t('nav.admin.accessKeys')}
        extra={[
          <Button
            key="add"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            {t('actions.add')} {t('entity.admin.accessKey._singular')}
          </Button>,
        ]}
      />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />

      <Modal
        title={`${t('actions.add')} ${t('entity.admin.accessKey._singular')}`}
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={handleCreateAccessKey}
          autoComplete="off"
        >
          <Form.Item label={t('entity.admin.accessKey.entity')} name="entity">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AccessKeysList;
