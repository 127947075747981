import React from 'react';
import { Typography } from 'antd';

import logoImage from '../assets/logo.png';

const TitleLogo = () => (
  <div style={{ textAlign: 'center', padding: '2rem 0' }}>
    <Typography.Title level={2}>
      <img
        src={logoImage}
        alt="eAST"
        width="48"
        height="48"
        style={{
          marginRight: '.5rem',
          boxSizing: 'content-box',
          border: '2px solid #fff',
        }}
      />{' '}
      eAST
    </Typography.Title>
    <Typography.Text type="secondary">
      Emitere Autorizație Specială de Transport
    </Typography.Text>
  </div>
);

export default TitleLogo;
