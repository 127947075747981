import React, { useCallback, useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { Clean } from '../../layouts';

import { findByIdent } from '../../services/east/permits';
import ViewPermitForm from '../../components/east/ViewPermitForm';

const ViewPermit = () => {
  const history = useHistory();
  const { ident } = useParams();

  const [permit, setPermit] = useState(null);

  useEffect(() => {
    if (ident) {
      findByIdent(ident).then((res) => setPermit(res));
    }
  }, [ident, setPermit]);

  const handleCancel = useCallback(() => history.push('/east'), [history]);

  return (
    <Clean>
      {permit && (
        <ViewPermitForm ident={ident} permit={permit} onCancel={handleCancel} />
      )}
    </Clean>
  );
};

export default ViewPermit;
