import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Checkbox, Input, Space, Row, Col, Alert } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import ReCAPTCHA from 'react-google-recaptcha';

import { SERVICE_URI, BASE_URI } from '../../services/auth';

const LoginForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();

  const isInvalid = useMemo(() => search === '?invalid', [search]);

  const handleForgotPassword = () => {
    history.push('/reset');
  };

  const handleRegisterClick = () => {
    history.push('/register');
  };
  return (
    <>
      <form
        method="POST"
        action={`${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/local`}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Input
            name="username"
            size="large"
            placeholder="Username"
            prefix={<UserOutlined />}
          />

          <Input
            name="password"
            type="password"
            size="large"
            placeholder="Password"
            prefix={<UserOutlined />}
          />

          <ReCAPTCHA
            name="recaptcha"
            sitekey={window._env_.RECAPTCHA_SITE_KEY}
            hl="ro"
          />

          {isInvalid && (
            <Alert description={t('errors.loginError')} type="error" />
          )}

          <Row gutter={16}>
            <Col span={12}>
              <Checkbox name="remember">Ține-mă minte</Checkbox>
            </Col>
            <Col span={12} style={{ textAlign: 'right', marginTop: '-5px' }}>
              <Button type="link" onClick={handleForgotPassword}>
                Ați uitat parola?
              </Button>
            </Col>
          </Row>

          <Button type="primary" block htmlType="submit">
            Sign in
          </Button>

          <Button type="link" block onClick={handleRegisterClick}>
            Înregistrare
          </Button>
        </Space>
      </form>
    </>
  );
};

export default LoginForm;
