import { PageHeader } from '@ant-design/pro-layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Default } from '../layouts';

const Home = () => {
  const { t } = useTranslation();

  return (
    <Default>
      <PageHeader title={t('nav.home')} />
    </Default>
  );
};

export default Home;
