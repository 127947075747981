import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Divider, Input } from 'antd';

const ApplicantFullSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Divider plain>{t('entity.east.permit.applicant')}</Divider>

      <Form.Item
        name="transportLegalName"
        label={t('entity.east.permit.transportName')}
      >
        <Input readOnly />
      </Form.Item>
      <Form.Item
        name="applicantLegalName"
        label={t('entity.east.permit.applicantNameView')}
      >
        <Input readOnly />
      </Form.Item>
      <Form.Item
        name="applicantName"
        label={t('entity.east.permit.applicantName')}
      >
        <Input readOnly />
      </Form.Item>
      <Form.Item
        name="applicantPersonalCode"
        label={t('entity.east.permit.applicantPersonalCode')}
      >
        <Input readOnly />
      </Form.Item>
    </>
  );
};
export default ApplicantFullSection;
