import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { MenuOutlined } from '@ant-design/icons';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

import useDatasource from '../../../hooks/useDatasource';

import Column from '../../../helpers/Columns';
import {
  findAll,
  remove,
  order as orderHOF,
} from '../../../services/admin/constants';

import EditItemIcon from '../../buttons/EditItemIcon';
import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import AddEntityButton from '../../buttons/AddEntityButton';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const DO_NOTHING = () => Promise.resolve({ content: [] });

const ConstantsList = ({ group, values }) => {
  const { t } = useTranslation();

  const fetcher = useMemo(() => findAll(group), [group]);

  const { loading, pagination, content, handleChange, reload } = useDatasource(
    fetcher || DO_NOTHING,
  );

  const [allowOrdering, setAllowOrdering] = useState(false);

  const handleChangeWrapper = useCallback(
    (page, filter, sort) => {
      const { field, order } = sort;
      setAllowOrdering(field === 'position' && order === 'ascend');
      handleChange(page, filter, sort);
    },
    [handleChange],
  );

  const [orderedContent, setOrderedContent] = useState([]);
  useEffect(() => setOrderedContent(content || []), [content]);

  const columns = useMemo(
    () =>
      [
        allowOrdering
          ? {
              title: 'Sort',
              dataIndex: 'sort',
              width: 50,
              render: () => <DragHandle />,
            }
          : null,
        Column.text('name', t('entity.admin.constant.name'), {
          width: 200,
          filter: true,
        }),
        Column.text(
          'expression',
          t(`entity.admin.constant.groups.${group}.expression`),
          {
            width: 200,
            filter: true,
          },
        ),

        values >= 1
          ? Column.text(
              'value1',
              t(`entity.admin.constant.groups.${group}.value1`),
              {
                width: 120,
              },
            )
          : null,
        values >= 2
          ? Column.text(
              'value2',
              t(`entity.admin.constant.groups.${group}.value2`),
              {
                width: 120,
              },
            )
          : null,
        values >= 3
          ? Column.text(
              'value3',
              t(`entity.admin.constant.groups.${group}.value3`),
              {
                width: 120,
              },
            )
          : null,
        values >= 4
          ? Column.text(
              'value4',
              t(`entity.admin.constant.groups.${group}.value4`),
              {
                width: 120,
              },
            )
          : null,
        Column.date('validAt', t('entity.admin.constant.validAt'), {
          width: 100,
        }),
        Column.text('position', t('entity.admin.constant.position'), {
          width: 100,
        }),

        Column.date('createdAt', t('entity._.createdAt'), {
          width: 100,
        }),
        Column.text('createdBy', t('entity._.createdBy'), {
          width: 150,
        }),
        Column.date('createdAt', t('entity._.updatedAt'), {
          width: 100,
        }),
        Column.text('createdBy', t('entity._.updatedBy'), {
          width: 150,
        }),

        Column.bool('deleted', t('entity._.deleted._'), {
          width: 150,
          filter: true,
          inverted: true,
          labels: [
            t('table.filter.all'),
            t('entity._.deleted.true'),
            t('entity._.deleted.false'),
            t('entity._.deleted.unknown'),
          ],
        }),

        Column.actions(t('table.actions'), (record) => (
          <span style={{ textAlign: 'right' }}>
            <EditItemIcon
              path={`/admin/system/constants/${group.toLowerCase()}/${
                record.id
              }`}
            />
            <DeleteItemIcon
              title={t('entity.admin.constant._delete', record)}
              message={t('entity.admin.constant._deleted', record)}
              item={record}
              action={remove(group)}
              reload={reload}
            />
          </span>
        )),
      ].filter((v) => v !== null),
    [t, group, values, reload, allowOrdering],
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        orderedContent.splice(
          newIndex,
          0,
          orderedContent.splice(oldIndex, 1)[0],
        );

        const { current, pageSize } = pagination;

        const result = orderedContent.map((row, index) => {
          // eslint-disable-next-line no-param-reassign
          row.position = (current - 1) * pageSize + index;
          return row;
        });

        const payload = result.reduce(
          (acc, row) => ({
            ...acc,
            [row.id]: row.position,
          }),
          {},
        );

        orderHOF(group)(payload)
          .then(() => setOrderedContent(result))
          .catch((err) => console.error(err));
      }
    },
    [group, orderedContent, pagination],
  );

  const DraggableContainer = React.memo((props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  ));

  const DraggableBodyRow = React.memo(({ className, style, ...restProps }) => {
    const index = orderedContent.findIndex(
      // eslint-disable-next-line eqeqeq
      (row) => row.id == restProps['data-row-key'],
    );
    return <SortableItem index={index} {...restProps} />;
  });

  return (
    <>
      <PageHeader
        title={t(`entity.admin.constant.groups.${group}.title`)}
        extra={[
          <AddEntityButton
            key="new"
            entity={t(`entity.admin.constant.groups.${group}.title`)}
            path={`/admin/system/constants/${group.toLowerCase()}/new`}
          />,
        ]}
      />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={orderedContent}
        scroll={{ x: 2000 }}
        onChange={handleChangeWrapper}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </>
  );
};

export default ConstantsList;
