import React, { useEffect, useState, useCallback } from 'react';

import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Default } from '../../../layouts';

import { getCurrent, updateCurrent } from '../../../services/admin/users/users';

import EditUserInfoForm from '../../../components/admin/users/EditUserInfoForm';

const EditUserInfo = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState({});
  const [errors, setErrors] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getCurrent().then(setUserInfo);
  }, [setUserInfo]);

  const handleSubmit = useCallback(
    (value) => {
      setErrors(null);
      return updateCurrent(value)
        .then(() => {
          notification.success({
            message: t('actions.saveSuccessful'),
          });
          const url = '/east';
          history.push(url);
        })
        .catch((msg) => {
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};

          setErrors(inner);

          notification.error({
            message: messageCode ? t(messageCode) : t('actions.saveFailed'),
          });
          return Promise.reject(msg);
        });
    },
    [history, t],
  );

  const handleCancel = useCallback(() => history.push('/east'), [history]);

  return (
    <Default>
      {userInfo !== undefined && (
        <EditUserInfoForm
          user={userInfo}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </Default>
  );
};

export default EditUserInfo;
