import React from 'react';

import { Default } from '../../../layouts';

import { useEditPage } from '../../../hooks/useEditPage';

import { findOne, create, update } from '../../../services/admin/geo/cities';

import EditCityForm from '../../../components/admin/geo/EditCityForm';

const initial = {};

const EditCity = () => {
  const [city, handleSubmit, handleCancel, errors] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/geo',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {city !== undefined && (
        <EditCityForm
          city={city}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </Default>
  );
};

export default EditCity;
