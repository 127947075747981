import React, { useState, useEffect, useMemo } from 'react';

import { Button, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { findOne } from '../services/admin/files';

const noopFileUrlSet = (str) => str;

const FileInput = ({
  label,
  scope,
  value,
  onChange,
  disabled,
  onFileUrlSet,
}) => {
  const [uploadedFile, setUploadedFile] = useState([]);

  useEffect(() => {
    const num = value ? Number(value) : NaN;
    if (Number.isInteger(num)) {
      findOne(num).then((res) => {
        res.url = (onFileUrlSet || noopFileUrlSet)(
          `${window._env_.API_BACKEND_URL}/files/v1/${res.id}?scope=${scope}&download`,
          res,
        );
        setUploadedFile([res]);
      });
    }
  }, [scope, value, onFileUrlSet]);

  const props = useMemo(
    () => ({
      name: 'file',
      multiple: false,
      action: `${window._env_.API_BACKEND_URL}/files/v1?scope=${scope}`,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
      },

      beforeUpload: (file) => {
        const isAllowed =
          file.type === 'image/png' ||
          file.type === 'image/jpg' ||
          file.type === 'image/jpeg' ||
          file.type === 'application/pdf';

        if (!isAllowed) {
          notification.error({
            message: `Eroare la incarcarea fisierului. Se permit pentru incarcare doar fisiere PNG, JPG sau PDF`,
          });
        }

        return isAllowed || Upload.LIST_IGNORE;
      },

      onChange(info) {
        const fileList = info.fileList.slice(-1).map((file) => {
          if (file.response) {
            const { nonce } = file.response;
            onChange(nonce);

            // eslint-disable-next-line no-param-reassign
            file.url = `${window._env_.API_BACKEND_URL}/files/v1/${nonce}/nonce?scope=${scope}&download`;
          }
          return file;
        });

        setUploadedFile(fileList);
      },

      onRemove() {
        return false;
      },
    }),
    [onChange, scope],
  );

  return (
    <Upload
      {...props}
      fileList={uploadedFile}
      showUploadList={{ showRemoveIcon: false }}
      disabled={disabled}
    >
      {disabled ? (
        <label>{label}</label>
      ) : (
        <Button icon={<UploadOutlined />}>{label}</Button>
      )}
    </Upload>
  );
};

export default FileInput;
