import React from 'react';

import { Row, Col } from 'antd';
import LanguageChooser from '../components/LanguageChooser';

const Empty = ({ children }) => {
  return (
    <>
      <Row style={{ padding: '1rem 0' }}>
        <Col xs={24} style={{ textAlign: 'right' }}>
          <LanguageChooser />
        </Col>
      </Row>

      {children}
    </>
  );
};

export default Empty;
