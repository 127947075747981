import React from 'react';

import { Default } from '../../../layouts';

import { useEditPage } from '../../../hooks/useEditPage';

import { findOne, create, update } from '../../../services/admin/users/users';

import EditUserForm from '../../../components/admin/users/EditUserForm';

const initial = {};

const EditRole = () => {
  const [user, handleSubmit, handleCancel, errors] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/users',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {user !== undefined && (
        <EditUserForm
          user={user}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </Default>
  );
};

export default EditRole;
