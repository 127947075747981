import React from 'react';
import { Select } from 'antd';

import useDictionaries from '../hooks/useDictionaries';
import { findAll } from '../services/admin/geo/countries';

const dictionaries = {
  countries: findAll,
};

const CountrySelect = ({ value, onChange, ...rest }) => {
  const [{ countries }] = useDictionaries(dictionaries);
  const { loading, content } = countries;

  return (
    <Select
      {...rest}
      style={{ width: '100%' }}
      loading={loading}
      value={value}
      onChange={onChange}
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
      }
    >
      {content.map((row) => (
        <Select.Option value={row.id} key={row.id}>
          {row.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CountrySelect;
