import { genericService } from '../../../helpers/api';

export const SERVICE_URI = '/published';
export const BASE_URI = '/v1/keys';

export const generateNewKey = (record, options = {}) =>
  fetch(
    `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/${record.id}/secret`,
    {
      method: 'DELETE',
      credentials: 'include',
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...(options.headers || {}),
      },
    },
  )
    .then((resp) => resp.json())
    .catch(() => Promise.resolve({ status: 0 }));

export const { findAll, create, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);
