import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Divider, Input, Checkbox, InputNumber } from 'antd';

import useFormContext from '../../../../hooks/useFormContext';
import useDefaultValues from '../../../../hooks/useDefaultValues';

import VehicleTypeSelect from '../../../VehicleTypeSelect';
import BooleanSelect from '../../../BooleanSelect';
import FileInput from '../../../FileInput';
import AxisGroup from '../../../AxisGroup';

const defaultAxis = {
  optionEngine: false,
  optionPneumatic: false,
  optionTwin: false,
  optionOther: false,
};

const defaultValues = {
  vehicleType: 'CAR',
  vehicleAxis: '2',
  optionRefrigerator: false,
  optionAltFuel: false,
  optionSpareTrailer: false,
  optionExceed: false,
  optionContainer: false,
  optionPassenger: false,
  optionArticulated: false,
  axisOptions: [{ ...defaultAxis }, { ...defaultAxis, distance: 0 }],
};

const VehicleTab = ({ disabled, permit }) => {
  const { t } = useTranslation();

  const form = useFormContext();
  useDefaultValues(form, defaultValues);

  const handleOnChangeAxisCount = useCallback(() => {
    const { vehicleType, vehicleAxis, trailerAxis, axisOptions } =
      form.getFieldsValue([
        'vehicleType',
        'vehicleAxis',
        'trailerAxis',
        'axisOptions',
      ]);

    const axisCount =
      (parseInt(vehicleAxis, 10) || 0) +
      (vehicleType === 'CAR' ? 0 : parseInt(trailerAxis, 10) || 0);

    const oldValue = Array.isArray(axisOptions) ? axisOptions : [];

    if (oldValue.length !== axisCount) {
      const newValue = new Array(axisCount).fill(null).map((_, idx) =>
        oldValue.length > idx
          ? {
              ...oldValue[idx],
              distance:
                oldValue[idx].distance > 0 ? oldValue[idx].distance : undefined,
            }
          : { ...defaultAxis },
      );
      newValue[newValue.length - 1].distance = 0;

      form.setFieldsValue({ axisOptions: newValue });
    }
  }, [form]);

  const handleVehicleTypeChange = useCallback(() => {
    form.setFieldsValue({
      trailerAxis: null,
      spareVehiclePlateNumber: null,
      spareTrailerPlateNumber: null,
    });
    handleOnChangeAxisCount();
  }, [form, handleOnChangeAxisCount]);

  const handleHasSpareVehicle = useCallback(() => {
    form.setFieldsValue({
      spareVehiclePlateNumber: null,
      spareTrailerPlateNumber: null,
    });
  }, [form]);

  return (
    <>
      <Row gutter={{ md: 32, lg: 64 }}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Divider plain>{t('entity.east.permit.vehicleType')}</Divider>
          <Form.Item
            name="vehicleType"
            label={t('entity.east.permit.vehicleType')}
          >
            <VehicleTypeSelect
              disabled={disabled}
              onChange={() => {
                handleVehicleTypeChange();
              }}
            />
          </Form.Item>

          <Row gutter={16}>
            <Col span={14}>
              <Form.Item
                name="vehiclePlateNumber"
                label={t('entity.east.permit.vehiclePlateNumber')}
              >
                <Input
                  disabled={disabled}
                  style={{ textTransform: 'uppercase' }}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="vehicleAxis"
                label={t('entity.east.permit.vehicleAxis')}
              >
                <InputNumber
                  min={2}
                  step={1}
                  onChange={() => {
                    handleOnChangeAxisCount();
                  }}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="vehicleIdentityCard">
            <FileInput
              scope="vhidc"
              label={t('entity.east.permit.vehicleIdentityCard')}
              disabled={disabled}
            />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.vehicleType !== next.vehicleType}
          >
            {({ getFieldValue }) => (
              <>
                {getFieldValue('vehicleType') === 'SEMITRAILER' && (
                  <Row gutter={16}>
                    <Col span={14}>
                      <Form.Item
                        name="trailerPlateNumber"
                        label={t('entity.east.permit.trailerPlateNumber')}
                      >
                        <Input
                          disabled={disabled}
                          style={{ textTransform: 'uppercase' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name="trailerAxis"
                        label={t('entity.east.permit.trailerAxis')}
                      >
                        <InputNumber
                          min={1}
                          step={1}
                          onChange={() => {
                            handleOnChangeAxisCount();
                          }}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {getFieldValue('vehicleType') === 'TRAILER' && (
                  <Row gutter={16}>
                    <Col span={14}>
                      <Form.Item
                        name="trailerPlateNumber"
                        label={t('entity.east.permit.trailerPlateNumber')}
                      >
                        <Input
                          disabled={disabled}
                          style={{ textTransform: 'uppercase' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name="trailerAxis"
                        label={t('entity.east.permit.trailerAxis')}
                      >
                        <InputNumber
                          min={1}
                          step={1}
                          onChange={() => {
                            handleOnChangeAxisCount();
                          }}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {(getFieldValue('vehicleType') === 'TRAILER' ||
                  getFieldValue('vehicleType') === 'SEMITRAILER') && (
                  <Form.Item name="trailerIdentityCard">
                    <FileInput
                      scope="stidc"
                      label={t('entity.east.permit.trailerIdentityCard')}
                      disabled={disabled}
                    />
                  </Form.Item>
                )}
              </>
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Divider plain>{t('entity.east.permit.vehicleInfo')}</Divider>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="optionRefrigerator"
                label={t('entity.east.permit.optionRefrigerator')}
              >
                <BooleanSelect disabled={disabled} />
              </Form.Item>
              <Form.Item
                name="optionAltFuel"
                label={t('entity.east.permit.optionAltFuel')}
              >
                <BooleanSelect disabled={disabled} />
              </Form.Item>
              <Form.Item
                name="optionSpareTrailer"
                label={t('entity.east.permit.optionSpareTrailer')}
              >
                <BooleanSelect
                  onChange={handleHasSpareVehicle}
                  disabled={disabled}
                />
              </Form.Item>
              <Form.Item
                name="optionExceed"
                label={t('entity.east.permit.optionExceed')}
              >
                <BooleanSelect disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="optionContainer"
                label={t('entity.east.permit.optionContainer')}
              >
                <BooleanSelect disabled={disabled} />
              </Form.Item>
              <Form.Item
                name="optionPassenger"
                label={t('entity.east.permit.optionPassenger')}
              >
                <BooleanSelect disabled={disabled} />
              </Form.Item>
              <Form.Item
                name="optionArticulated"
                label={t('entity.east.permit.optionArticulated')}
              >
                <BooleanSelect disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.optionSpareTrailer !== next.optionSpareTrailer ||
            prev.vehicleType !== next.vehicleType
          }
        >
          {({ getFieldValue }) => (
            <>
              {getFieldValue('optionSpareTrailer') && (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Divider plain>
                    {t('entity.east.permit.backupVehicle')}
                  </Divider>

                  {permit.requestType !== 'PERMANENT' && (
                    <Form.Item
                      name="spareVehiclePlateNumber"
                      label={t('entity.east.permit.spareVehiclePlateNumber')}
                    >
                      <Input
                        disabled={disabled}
                        style={{ textTransform: 'uppercase' }}
                      />
                    </Form.Item>
                  )}
                  {['SEMITRAILER', 'TRAILER'].includes(
                    getFieldValue('vehicleType'),
                  ) && (
                    <Form.Item
                      name="spareTrailerPlateNumber"
                      label={t('entity.east.permit.spareTrailerPlateNumber')}
                    >
                      <Input
                        disabled={disabled}
                        style={{ textTransform: 'uppercase' }}
                      />
                    </Form.Item>
                  )}
                </Col>
              )}
            </>
          )}
        </Form.Item>
      </Row>

      <Divider plain style={{ 'font-weight': 'bold', 'font-size': '1.5em' }}>
        {t('entity.east.permit.axisConfiguration')}
      </Divider>

      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => prev.axisOptions !== next.axisOptions}
      >
        {({ getFieldValue }) => {
          const vehicleAxis = parseInt(getFieldValue('vehicleAxis'), 10) || 0;

          return (
            <Form.List name="axisOptions">
              {(fields) => (
                <div className="axis__wrapper">
                  {fields.map(({ name, fieldKey }, idx) => (
                    <div
                      key={fieldKey}
                      className={`axis ${idx < vehicleAxis ? 'axis--car' : ''}`}
                    >
                      <label className="axis__label">Axa {idx + 1}</label>
                      {idx < vehicleAxis && (
                        <Form.Item
                          name={[name, 'optionEngine']}
                          noStyle
                          fieldKey={[fieldKey, 'optionEngine']}
                          valuePropName="checked"
                        >
                          <Checkbox
                            className="axis__check-e"
                            disabled={disabled}
                          >
                            M
                          </Checkbox>
                        </Form.Item>
                      )}
                      <Form.Item
                        name={[name, 'optionPneumatic']}
                        noStyle
                        fieldKey={[fieldKey, 'optionPneumatic']}
                        valuePropName="checked"
                      >
                        <Checkbox className="axis__check-p" disabled={disabled}>
                          P
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name={[name, 'optionTwin']}
                        noStyle
                        fieldKey={[fieldKey, 'optionTwin']}
                        valuePropName="checked"
                      >
                        <Checkbox className="axis__check-t" disabled={disabled}>
                          J
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name={[name, 'optionOther']}
                        noStyle
                        fieldKey={[fieldKey, 'optionOther']}
                        valuePropName="checked"
                      >
                        <Checkbox className="axis__check-o" disabled={disabled}>
                          A
                        </Checkbox>
                      </Form.Item>
                      {idx < fields.length - 1 && (
                        <Form.Item
                          name={[name, 'distance']}
                          noStyle
                          fieldKey={[fieldKey, 'distance']}
                        >
                          <InputNumber
                            min={0.1}
                            step={0.1}
                            precision={2}
                            className="axis__distance"
                            disabled={disabled}
                          />
                        </Form.Item>
                      )}
                      <Form.Item
                        name={[name, 'axisCount']}
                        noStyle
                        fieldKey={[fieldKey, 'axisCount']}
                      >
                        <AxisGroup />
                      </Form.Item>
                      <div className="axis__errors">
                        <Form.Item
                          name={[name, 'optionEngine']}
                          fieldKey={[fieldKey, 'optionEngine']}
                        />
                        <Form.Item
                          name={[name, 'optionOther']}
                          fieldKey={[fieldKey, 'optionOther']}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Form.List>
          );
        }}
      </Form.Item>

      <div className="axis__legend">
        <Divider plain>Legenda</Divider>
        <dl>
          <dt>M</dt>
          <dd>Axă motoare</dd>
          <dt>P</dt>
          <dd>Axă cu suspensie pneumatică</dd>
          <dt>J</dt>
          <dd>Axă cu roți jumelate</dd>
          <dt>A</dt>
          <dd>Axă cu alt tip de suspensie</dd>

          <dt className="group">Axe albastre</dt>
          <dd className="group">Vehicul Tractor</dd>
          <dt>Axe gri</dt>
          <dd>Remorca/Semiremorca</dd>
        </dl>
      </div>
    </>
  );
};

export default VehicleTab;
