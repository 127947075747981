import React, { useMemo } from 'react';

import { Form, Input, Row, Col, Space } from 'antd';

import { useTranslation } from 'react-i18next';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';
import TableFormItemWrapper from '../../TableFormItemWrapper';
import useDatasource from '../../../hooks/useDatasource';
import useFormErrors from '../../../hooks/useFormErrors';

import Column from '../../../helpers/Columns';

import { findAll } from '../../../services/admin/geo/counties';

const EditSubdivisionForm = ({ subdivision, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  useFormErrors(form, errors);

  const { loading, pagination, content, handleChange } = useDatasource(
    findAll,
    { pagination: { pageSize: 50, showSizeChanger: false } },
  );

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.admin.county.name'), {
        width: 300,
      }),
    ],
    [t],
  );
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={subdivision}
        onFinish={onSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={10} lg={8} xl={8}>
            <Form.Item label={t('entity.admin.subdivision.name')} name="name">
              <Input />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.subdivision.address')}
              name="address"
            >
              <Input />
            </Form.Item>
            <Form.Item label={t('entity.admin.subdivision.phone')} name="phone">
              <Input />
            </Form.Item>
            <Form.Item label={t('entity.admin.subdivision.email')} name="email">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={14} lg={16} xl={16}>
            <Form.Item name="counties" noStyle>
              <TableFormItemWrapper
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={pagination}
                dataSource={content}
                onTableChange={handleChange}
                scroll={{ y: 560 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <SaveButton />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditSubdivisionForm;
