export const CONSTANTS = {
  AXIS_GROUPING: {
    values: 2,
    expressions: ['DOUBLE_AXIS_LIMIT', 'TRIPLE_AXIS_LIMIT'],
  },

  ALLOWED_LENGTH: { values: 4 },
  ALLOWED_HEIGHT: { values: 4 },
  ALLOWED_WIDTH: { values: 4 },
  ALLOWED_WEIGHT: { values: 4 },
  WEIGHT_PER_AXIS: { values: 4 },

  FARE_CONSTANTS: {
    values: 4,
    expressions: ['FARE_HEIGHT', 'FARE_WIDTH', 'FARE_LENGHT', 'FARE_WEIGHT'],
  },

  EXCHANGE_RATE: { values: 1 },
  ISSUE_LIMIT: { values: 1 },

  ALLOWED_ISSUING: { values: 1 },

  FARE_CALCULUS: {
    values: 1,
  },

  REQUIRES_ESCORT: { values: 1 },
  REQUIRES_POLICE: { values: 1 },

  SYSTEM_PARAMETRES: {},
};
