import React, { useCallback } from 'react';

import { Spin } from 'antd';

import { Default } from '../../layouts';

import { useEditPage } from '../../hooks/useEditPage';

import { findOne, create, updateTab } from '../../services/east/permits';

import PermitForm from '../../components/east/PermitForm';

const initial = {
  status: 'NEW',
};

const findOneWrapper = (id) => findOne(id);

const goForward = (value) => {
  const { id } = value;
  return `!/east/${id}?${Math.random()}`;
};

const EditPermit = () => {
  const handleUpdate = useCallback(
    (value) => updateTab(value.status.toLowerCase())(value),
    [],
  );

  const [permit, handleSubmit, handleCancel, errors] = useEditPage({
    initial,
    existent: findOneWrapper,
    onCreate: create,
    onUpdate: handleUpdate,
    goBackPath: '/east/',
    goForwardPath: goForward,
  });

  return (
    <Default>
      {permit ? (
        <PermitForm
          permit={permit}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      ) : (
        <Spin size="large" style={{ display: 'block', textAlign: 'center' }} />
      )}
    </Default>
  );
};

export default EditPermit;
