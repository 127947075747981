import React, { useMemo } from 'react';
import { Input } from 'antd';

const TransrouteDisplay = ({ value }) => {
  const content = useMemo(() => {
    if (!Array.isArray(value)) {
      return '';
    }

    return value
      .sort((a, b) => {
        return a.totalDistance - b.totalDistance;
      })
      .map((row) => {
        return row.name;
      })
      .join('; ');
  }, [value]);

  return <Input.TextArea value={content} readOnly rows={3} />;
};
export default TransrouteDisplay;
