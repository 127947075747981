import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { notification } from 'antd';
import PermitsList from '../../components/east/PermitsList';
import { findAll } from '../../services/message';

import { Default } from '../../layouts';

const Permits = () => {
  const history = useHistory();
  useEffect(() => {
    if (!history.location.key) {
      findAll({
        pageSize: 5000,
        pageNumber: 1,
      }).then((res) => {
        const message = res.content.find(
          (item) => item.name === 'MESSAGE_AFTER_LOGIN',
        );
        if (
          new Date(message.validAt) < new Date() &&
          new Date(message.validTo) > new Date()
        ) {
          notification.info({
            message: message.expression,
            duration: 0,
          });
        }
      });
    }
  }, [history]);
  console.log(history);
  return (
    <Default>
      <PermitsList />
    </Default>
  );
};

export default Permits;
