import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, notification, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { PrinterOutlined } from '@ant-design/icons';

import Column from '../../helpers/Columns';
import {
  listReports,
  getReportDefinition,
  generateReport,
} from '../../services/reports/reports';
import ReportWindow from './ReportWindow';

const ReportsList = () => {
  const { t } = useTranslation();

  const [reports, setReports] = useState(null);
  const [reportDefinition, setReportDefinition] = useState(null);

  useEffect(() => {
    listReports()
      .then((list) => setReports(list))
      .catch((err) => {
        console.error(err);
        setReports([]);
        notification.error({
          message: `Eroare la incarcarea listei de rapoarte: ${err}`,
        });
      });
  }, []);

  const loadReport = useCallback((report) => {
    getReportDefinition(report.uri)
      .then((res) => setReportDefinition(res))
      .catch((err) => {
        console.error(err);
        notification.error({
          message: `${err}`,
        });
      });
  }, []);

  const columns = useMemo(
    () => [
      Column.text('label', t('reports.label'), {
        width: 120,
        filter: true,
      }),
      Column.text('description', t('reports.description'), {
        width: 300,
        filter: true,
      }),
      Column.actions(
        t('table.actions'),
        (record) => (
          <span style={{ textAlign: 'right' }}>
            <Button
              shape="circle"
              type="link"
              icon={<PrinterOutlined />}
              onClick={() => loadReport(record)}
            />
          </span>
        ),
        { width: 60 },
      ),
    ],
    [loadReport, t],
  );

  const handlePrint = useCallback(
    (report) => {
      const { label } = reportDefinition;
      const { _format } = report;
      generateReport(reportDefinition.uri, report, `${label}.${_format}`)
        .then(() => {
          notification.success({
            message: `Raportul a fost generat cu succes`,
          });
          setReportDefinition(null);
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: `${err}`,
          });
        });
    },
    [reportDefinition],
  );

  return (
    <>
      <PageHeader title={t('nav.reports')} />
      <Table
        columns={columns}
        rowKey="uri"
        loading={reports == null}
        pagination={false}
        dataSource={reports}
      />
      <ReportWindow
        report={reportDefinition}
        onPrint={handlePrint}
        onClose={() => setReportDefinition(null)}
      />
    </>
  );
};

export default ReportsList;
