import React, { useMemo, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Menu } from 'antd';
import {
  SettingOutlined,
  UserOutlined,
  AimOutlined,
  FormOutlined,
  GlobalOutlined,
  ControlOutlined,
  PrinterOutlined,
  KeyOutlined,
} from '@ant-design/icons';

import AuthContext, { hasPermission } from './auth';

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const { t } = useTranslation();

  const history = useHistory();

  const { pathname } = useLocation();

  const selectedKeys = useMemo(
    () =>
      pathname.split('/').map((_, idx, arr) => arr.slice(0, idx + 1).join('/')),
    [pathname],
  );
  const defaultOpenKeys = useMemo(
    () =>
      pathname
        .split('/')
        .reverse()
        .slice(1)
        .reverse()
        .map((_, idx, arr) => arr.slice(0, idx + 1).join('/')),
    [pathname],
  );

  const handleSelect = ({ key }) => key && history.push(key);

  return (
    <Menu
      mode="inline"
      style={{
        height: '100vh',
        borderRight: 0,
        paddingTop: '64px',
        overflowX: 'hidden',
        overflow: 'auto',
      }}
      defaultOpenKeys={defaultOpenKeys}
      selectedKeys={selectedKeys}
      onClick={handleSelect}
    >
      <Menu.Item key="/home" icon={<UserOutlined />}>
        {t('nav.home')}
      </Menu.Item>
      {
        // eslint-disable-next-line no-nested-ternary
        hasPermission(permissions, 'SYS_SUPERUSER') ? (
          <>
            <Menu.SubMenu
              key="/admin"
              icon={<SettingOutlined />}
              title={t('nav.admin._')}
            >
              <Menu.Item key="/admin/users" icon={<UserOutlined />}>
                {t('nav.admin.users')}
              </Menu.Item>
              <Menu.Item key="/admin/geo" icon={<GlobalOutlined />}>
                {t('nav.admin.geo')}
              </Menu.Item>
              <Menu.Item key="/admin/origins" icon={<AimOutlined />}>
                {t('nav.admin.origins')}
              </Menu.Item>
              <Menu.Item key="/admin/system" icon={<ControlOutlined />}>
                {t('nav.admin.system')}
              </Menu.Item>
              <Menu.Item key="/admin/access" icon={<KeyOutlined />}>
                {t('nav.admin.accessKeys')}
              </Menu.Item>
            </Menu.SubMenu>
          </>
        ) : hasPermission(permissions, 'CNAIR_CONTROL') ? (
          <>
            <Menu.Item key="/admin/users" icon={<UserOutlined />}>
              {t('nav.admin.usersOnly')}
            </Menu.Item>
          </>
        ) : null
      }
      {
        // eslint-disable-next-line no-nested-ternary
        hasPermission(permissions, ['SYS_SUPERUSER', 'CNAIR_CONTROL']) ? (
          <>
            <Menu.Item key="/reports" icon={<PrinterOutlined />}>
              {t('nav.reports')}
            </Menu.Item>
          </>
        ) : null
      }

      <Menu.Item key="/east" icon={<FormOutlined />}>
        {t('nav.east')}
      </Menu.Item>
    </Menu>
  );
};

export default Sidebar;
