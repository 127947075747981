import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Divider, Image, Row, Col, Space } from 'antd';

import useFormContext from '../../../../hooks/useFormContext';
import DownloadInvoiceButton from '../../../buttons/DownloadInvoiceButton';
import DownloadASTButton from '../../../buttons/DownloadASTButton';

const QRCodeAndDocsSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { ident } = useParams();
  return (
    <>
      <Divider plain>{t('entity.east.permit.qrAndInvoice')}</Divider>
      <div style={{ textAlign: 'center' }}>
        <Image
          width={200}
          height={200}
          preview={false}
          src={`data:image/png;base64,${form.getFieldValue(['qrCode'])}`}
        />
        <br />
        <Space align="center">
          <Row gutter={2} style={{ justifyContent: 'center' }}>
            <Col flex="100px">
              <DownloadInvoiceButton id={ident} />
            </Col>
            <Col flex="auto">
              <DownloadASTButton id={ident} />
            </Col>
          </Row>
        </Space>
      </div>
    </>
  );
};
export default QRCodeAndDocsSection;
