import React, { useMemo, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Steps,
  Form,
  Space,
  Input,
  Button,
  notification,
  Popconfirm,
} from 'antd';

import { RollbackOutlined, StepBackwardOutlined } from '@ant-design/icons';
import AuthContext, { hasPermission } from '../auth';

import { FormContextProvider } from '../../hooks/useFormContext';
import useFormErrors from '../../hooks/useFormErrors';
import { useRouteParams } from '../../hooks/useRouteParams';

import ApplicantTab from './tabs/applicant/ApplicantTab';
import VehicleTab from './tabs/vehicle/VehicleTab';
import EscortTab from './tabs/escort/EscortTab';
import RouteTab from './tabs/route/RouteTab';
import TermsTab from './tabs/terms/TermsTab';
import PaymentTab from './tabs/payment/PaymentTab';
import AuditTab from './tabs/audit/AuditTab';
import ErrorTab from './tabs/error/ErrorTab';
import CancelTab from './tabs/cancelled/CancelTab';

import NextButton from '../buttons/NextButton';
import CancelButton from '../buttons/CancelButton';
import PaymentButton from '../buttons/PaymentButton';
import { remove, goBack } from '../../services/east/permits';
import PaymentStatusTab from './tabs/PaymentStatusTab';

const status = (tabIndex, currentStep) =>
  // eslint-disable-next-line no-nested-ternary
  tabIndex < currentStep
    ? 'finish'
    : tabIndex > currentStep
    ? 'wait'
    : 'process';

const PermitForm = ({ permit, onSubmit, onCancel, errors }) => {
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const history = useHistory();

  const { search } = useLocation();

  const { paid, message } = useMemo(() => {
    const params = new URLSearchParams(search);
    const p = params.get('paid');
    return {
      // eslint-disable-next-line no-nested-ternary
      paid: p === 'true' ? true : p === 'false' ? false : null,
      message: params.get('message'),
    };
  }, [search]);

  const { t } = useTranslation();
  const { id } = useRouteParams();

  const [form] = Form.useForm();
  useFormErrors(form, errors);

  const [currentStep, setCurrentStep] = useState(-1);

  const stepBack = useCallback(
    () =>
      goBack(id, form.getFieldValue(['status'])).then(() => {
        window.location.reload();
        notification.success({
          message: t('actions.saveSuccessful'),
        });
      }),
    [form, id, t],
  );

  const permitStep = useMemo(() => {
    if (paid != null) {
      return 99;
    }

    if (!permit) {
      return 7;
    }

    switch (permit.status) {
      case 'NEW':
        return 0;
      case 'VEHICLE':
        return 1;
      case 'ROUTE':
        return 2;
      case 'ESCORT':
        return 3;
      case 'TERMS':
        return 4;
      case 'PAYMENT':
      case 'OUTDATING':
      case 'OUTDATED':
        return 5;
      case 'DENIED':
        return 8;
      case 'CANCELLED':
        return 9;
      case 'PAID':
        return 10;
      default:
        return 6;
    }
  }, [permit, paid]);

  const tabStep = useMemo(
    () => (currentStep === -1 ? permitStep : currentStep),
    [currentStep, permitStep],
  );

  return (
    <>
      <Steps
        type="navigation"
        size="small"
        current={tabStep}
        onChange={setCurrentStep}
      >
        <Steps.Step
          title={t('entity.east.permit.status.NEW')}
          status={status(0, permitStep)}
          disabled={permitStep < 0}
        />
        <Steps.Step
          title={t('entity.east.permit.status.VEHICLE')}
          status={status(1, permitStep)}
          disabled={permitStep < 1}
        />
        <Steps.Step
          title={t('entity.east.permit.status.ROUTE')}
          status={status(2, permitStep)}
          disabled={permitStep < 2}
        />
        <Steps.Step
          title={t('entity.east.permit.status.ESCORT')}
          status={status(3, permitStep)}
          disabled={permitStep < 3}
        />
        <Steps.Step
          title={t('entity.east.permit.status.TERMS')}
          status={status(4, permitStep)}
          disabled={permitStep < 4}
        />
        <Steps.Step
          title={t('entity.east.permit.status.PAYMENT')}
          status={status(5, permitStep)}
          disabled={permitStep < 5}
        />
        {(permitStep === 6 || permitStep === 10) &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'CNAIR_CONTROL']) && (
            <Steps.Step title={t('audit.tab')} />
          )}
      </Steps>

      <FormContextProvider form={form}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          initialValues={permit}
        >
          <Form.Item name="status" noStyle>
            <Input type="hidden" />
          </Form.Item>

          {tabStep === 0 && <ApplicantTab disabled={permitStep !== 0} />}
          {tabStep === 1 && (
            <VehicleTab disabled={permitStep !== 1} permit={permit} />
          )}
          {tabStep === 2 && <RouteTab disabled={permitStep !== 2} />}
          {tabStep === 3 && <EscortTab disabled={permitStep !== 3} />}
          {tabStep === 4 && <TermsTab disabled={permitStep !== 4} />}
          {(tabStep === 5 || tabStep === 10) && (
            <PaymentTab disabled={permitStep !== 5} />
          )}
          {tabStep === 6 &&
            hasPermission(permissions, ['SYS_SUPERUSER', 'CNAIR_CONTROL']) && (
              <AuditTab />
            )}
          {tabStep === 8 && <ErrorTab />}
          {tabStep === 9 && <CancelTab />}
          {tabStep === 99 && <PaymentStatusTab paid={paid} message={message} />}

          <Space
            style={{ justifyContent: 'end', width: '100%', paddingTop: '1rem' }}
          >
            <Button icon={<RollbackOutlined />} onClick={onCancel}>
              {t('actions.backToMenu')}
            </Button>
            {permitStep > 0 && permitStep < 5 && (
              <Popconfirm
                placement="topLeft"
                title={t('entity.east.permit.confirmStepBackMessage')}
                onConfirm={stepBack}
                okText={t('actions.confirmStepBack')}
                cancelText={t('actions.cancel')}
              >
                <Button icon={<StepBackwardOutlined />}>
                  {t('actions.stepBack')}
                </Button>
              </Popconfirm>
            )}

            {(permitStep === 1 ||
              permitStep === 2 ||
              permitStep === 3 ||
              permitStep === 4 ||
              permitStep === 5) && (
              <CancelButton
                onCancel={() =>
                  remove(permit)
                    .then(() => {
                      history.push('/east');
                      notification.success({
                        message: t('actions.deleteSuccessful'),
                      });
                    })
                    .catch(() =>
                      notification.error({
                        message: t('actions.error'),
                      }),
                    )
                }
              />
            )}
            {permitStep < 5 && <NextButton />}
            {permitStep === 5 && <PaymentButton />}
          </Space>
        </Form>
      </FormContextProvider>
    </>
  );
};

export default PermitForm;
