import React from 'react';

import { Form, Row, Col, Divider } from 'antd';

import moment from 'moment';

import useFormContext from '../../../../hooks/useFormContext';

import RouteOriginSelect from '../../../RouteOriginSelect';
import LocalDatePicker from '../../../LocalDatePicker';

const TransrouteSection = ({ name, fieldKey, disabled }) => {
  const form = useFormContext();

  const { requestType, routeType, periodStartDate, periodType } =
    form.getFieldsValue([
      'requestType',
      'routeType',
      'periodStartDate',
      'periodType',
    ]);

  const defaultEndDate = periodStartDate
    ? moment(periodStartDate)
        .add(
          // eslint-disable-next-line no-nested-ternary
          periodType === 'DAYS30'
            ? 30
            : // eslint-disable-next-line no-nested-ternary
            periodType === 'DAYS90'
            ? 90
            : periodType === 'DAYS365'
            ? 365
            : 0,
          'day',
        )
        .format('YYYY-MM-DD')
    : null;

  return (
    <>
      {(requestType === 'SINGLE' || routeType === 'SPECIFIC') && (
        <>
          <Divider plain>Traseu</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={[name, 'dateStart']}
                fieldKey={[fieldKey, 'dateStart']}
                label="Perioada de la "
                initialValue={periodStartDate}
              >
                <LocalDatePicker
                  disabledDate={(current) => {
                    return current < moment().add(1, 'day').startOf('day');
                  }}
                  disabled={disabled || routeType === 'SPECIFIC'}
                />
              </Form.Item>
              <Form.Item
                name={[name, 'originId']}
                fieldKey={[fieldKey, 'originId']}
                label="Origine"
              >
                <RouteOriginSelect disabled={disabled} />
              </Form.Item>
              <Form.Item
                name={[name, 'via1Id']}
                fieldKey={[fieldKey, 'via1Id']}
                label="Via 1"
              >
                <RouteOriginSelect disabled={disabled} allowClear />
              </Form.Item>
              <Form.Item
                name={[name, 'via3Id']}
                fieldKey={[fieldKey, 'via3Id']}
                label="Via 3"
              >
                <RouteOriginSelect disabled={disabled} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={[name, 'dateEnd']}
                fieldKey={[fieldKey, 'dateEnd']}
                label="Perioada pana la "
                initialValue={defaultEndDate}
              >
                <LocalDatePicker
                  disabledDate={(current) => {
                    return current < moment().add(1, 'day').startOf('day');
                  }}
                  disabled={disabled || routeType === 'SPECIFIC'}
                />
              </Form.Item>
              <Form.Item
                name={[name, 'destinationId']}
                fieldKey={[fieldKey, 'destinationId']}
                label="Destinatie"
              >
                <RouteOriginSelect disabled={disabled} />
              </Form.Item>
              <Form.Item
                name={[name, 'via2Id']}
                fieldKey={[fieldKey, 'via2Id']}
                label="Via 2"
              >
                <RouteOriginSelect disabled={disabled} allowClear />
              </Form.Item>
              <Form.Item
                name={[name, 'via4Id']}
                fieldKey={[fieldKey, 'via4Id']}
                label="Via 4"
              >
                <RouteOriginSelect disabled={disabled} allowClear />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default TransrouteSection;
