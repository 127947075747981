import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Divider, Input, Row, Col } from 'antd';

const OversizedVehicleSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Divider plain>{t('entity.east.permit.oversizedVehicle')}</Divider>
      <Form.Item
        name="vehiclePlateNumber"
        label={t('entity.east.permit.vehicleNumber')}
      >
        <Input readOnly />
      </Form.Item>
      <Form.Item
        name="trailerPlateNumber"
        label={t('entity.east.permit.trailerNumber')}
      >
        <Input readOnly />
      </Form.Item>
      <Form.Item
        name="spareVehiclePlateNumber"
        label={t('entity.east.permit.spareVehicleNumber')}
      >
        <Input readOnly />
      </Form.Item>
      <Form.Item
        name="spareTrailerPlateNumber"
        label={t('entity.east.permit.spareTrailerNumber')}
      >
        <Input readOnly />
      </Form.Item>
      <Form.Item
        // To create db field
        name="vehicleConfiguration"
        label={t('entity.east.permit.vehicleConfiguration')}
      >
        <Input readOnly />
      </Form.Item>
      <Form.List name="axisOptions">
        {(fields) => (
          // if (!fields?.length) {
          //   return null;
          // }
          // const { name, fieldKey } = fields.at(-1);
          // const idx = fields.length - 1;
          <>
            <Divider plain>Lungimea intre axe</Divider>
            <Row gutter={16} span={6}>
              {fields.slice(0, -1).map(({ fieldKey, name }, idx) => (
                <>
                  <Col
                    xs={12}
                    sm={12}
                    md={8}
                    lg={6}
                    key={[fields.at(-1), fieldKey]}
                    className="route"
                  >
                    <Form.Item
                      name={[name, 'distance']}
                      fieldKey={[fieldKey - 1, 'dateStart']}
                      label={`${t('Distanta axe')} ${idx + 1}-${idx + 2}`}
                    >
                      <Input readOnly />
                    </Form.Item>
                  </Col>
                </>
              ))}
            </Row>
          </>
        )}
      </Form.List>
    </>
  );
};
export default OversizedVehicleSection;
