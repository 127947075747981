import React from 'react';
import { Select } from 'antd';

const DivisibleSelect = ({ value, onSelect, ...rest }) => {
  return (
    <Select
      {...rest}
      style={{ width: '100%' }}
      value={value}
      onSelect={onSelect}
    >
      <Select.Option value={false} key="false">
        Indivizibila
      </Select.Option>
      <Select.Option value key="true">
        Divizibila
      </Select.Option>
    </Select>
  );
};

export default DivisibleSelect;
