import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Divider, Input } from 'antd';

import BooleanSelect from '../../../BooleanSelect';

const EscortVehicleSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Divider plain>{t('entity.east.permit.escortVehicle')}</Divider>

      <Form.List name="escortOptions">
        {(fields) => (
          <>
            {fields.map(({ fieldKey, name }, idx) => (
              <div key={fieldKey} className="route">
                <Divider plain>
                  {t('entity.east.permit.escortVehicle')} {idx + 1}
                </Divider>

                <Form.Item
                  name={[name, 'operatorId']}
                  label={t('entity.east.permit.escortVehicleOperator')}
                >
                  <Input readOnly />
                </Form.Item>

                <Form.Item
                  name={[name, 'authorizationNumber']}
                  label={t('entity.east.permit.authorizationNumber')}
                >
                  <Input readOnly />
                </Form.Item>

                <Form.Item
                  name={[name, 'vehicleId']}
                  label={t('entity.east.permit.escortVehicleNumber')}
                >
                  <Input readOnly />
                </Form.Item>
              </div>
            ))}
          </>
        )}
      </Form.List>
      <Form.Item name="requiresPolice" label={t('entity.east.permit.isPolice')}>
        <BooleanSelect open={false} />
      </Form.Item>
    </>
  );
};
export default EscortVehicleSection;
