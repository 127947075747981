import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';

const NextButton = () => {
  const { t } = useTranslation();

  return (
    <Button type="primary" htmlType="submit" icon={<RightCircleOutlined />}>
      {t('actions.next')}
    </Button>
  );
};

export default NextButton;
