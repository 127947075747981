import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Divider, Switch, Space, Typography } from 'antd';

import PhoneInput from 'react-phone-number-input';

import useFormContext from '../../../../hooks/useFormContext';
import CountrySelect from '../../../CountrySelect';
import CountySelect from '../../../CountySelect';
import CitySelect from '../../../CitySelect';
import FileInput from '../../../FileInput';

const ApplicantSection = ({ disabled, romaniaCountryCode }) => {
  const form = useFormContext();
  const { t } = useTranslation();

  const handleCountryChange = useCallback(() => {
    if (form.getFieldValue(['applicantCountryId'])) {
      form.setFieldsValue({
        applicantCityId: null,
        applicantCountyId: null,
      });
    }
  }, [form]);

  const handleCountyChange = useCallback(() => {
    if (form.getFieldValue(['applicantCountryId'])) {
      form.setFieldsValue({
        applicantCityId: null,
      });
    }
  }, [form]);

  const handleChangeInput = useCallback(() => {
    const { transportIsApplicant } = form.getFieldsValue([
      'transportIsApplicant',
    ]);

    if (transportIsApplicant) {
      const {
        applicantLegalName,
        applicantFiscalCode,
        applicantTradeRegisterCode,
        applicantCountryId,
        applicantCountyId,
        applicantCityId,
        applicantCity,
        applicantAddress,
        applicantPhone,
      } = form.getFieldsValue([
        'applicantLegalName',
        'applicantFiscalCode',
        'applicantTradeRegisterCode',
        'applicantCountryId',
        'applicantCountyId',
        'applicantCityId',
        'applicantCity',
        'applicantAddress',
        'applicantPhone',
      ]);

      form.setFieldsValue({
        transportLegalName: applicantLegalName,
        transportFiscalCode: applicantFiscalCode,
        transportTradeRegisterCode: applicantTradeRegisterCode,
        transportCountryId: applicantCountryId,
        transportCountyId: applicantCountyId,
        transportCityId: applicantCityId,
        transportCity: applicantCity,
        transportAddress: applicantAddress,
        transportPhone: applicantPhone,
      });
    }
  }, [form]);

  const handleTransportIsApplicantChange = useCallback(
    (value) => {
      if (value) {
        const {
          applicantLegalName,
          applicantPhone,
          applicantEmail,
          applicantFiscalCode,
          applicantTradeRegisterCode,
          applicantCountryId,
          applicantCountyId,
          applicantCityId,
          applicantCity,
          applicantAddress,
        } = form.getFieldsValue([
          'applicantLegalName',
          'applicantPhone',
          'applicantEmail',
          'applicantFiscalCode',
          'applicantTradeRegisterCode',
          'applicantCountryId',
          'applicantCountyId',
          'applicantCityId',
          'applicantCity',
          'applicantAddress',
        ]);

        form.setFieldsValue({
          transportLegalName: applicantLegalName,
          transportPhone: applicantPhone,
          transportEmail: applicantEmail,
          transportFiscalCode: applicantFiscalCode,
          transportTradeRegisterCode: applicantTradeRegisterCode,
          transportCountryId: applicantCountryId,
          transportCountyId: applicantCountyId,
          transportCityId: applicantCityId,
          transportCity: applicantCity,
          transportAddress: applicantAddress,
        });
      }
    },
    [form],
  );

  return (
    <>
      <Divider plain>{t('entity.east.permit.applicant')}</Divider>
      <Form.Item
        name="applicantName"
        label={t('entity.east.permit.applicantName')}
      >
        <Input
          disabled={disabled || !form.getFieldValue('isLegal')}
          onChange={handleChangeInput}
        />
      </Form.Item>
      <Form.Item
        name="applicantPersonalCode"
        label={t(`entity.east.permit.applicantPersonalCode`)}
      >
        <Input disabled={disabled || !form.getFieldValue('isLegal')} />
      </Form.Item>
      <Form.Item name="applicantIdentityCard">
        <FileInput
          scope="apidc"
          label={t(`entity.east.permit.applicantIdentityCard`)}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        name="applicantPhone"
        label={t('entity.east.permit.applicantPhone')}
        onChange={handleChangeInput}
      >
        <PhoneInput
          defaultCountry="RO"
          withCountryCallingCode
          disabled={disabled || !form.getFieldValue('isLegal')}
        />
      </Form.Item>
      <Form.Item
        name="applicantEmail"
        label={t('entity.east.permit.applicantEmail')}
        onChange={handleChangeInput}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="applicantLegalName"
        label={t(
          `entity.east.permit.${
            form.getFieldValue('isLegal')
              ? 'legalApplicantLegalName'
              : 'applicantLegalName'
          }`,
        )}
        onChange={handleChangeInput}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="applicantCountryId"
        label={t('entity.east.permit.applicantCountryId')}
      >
        <CountrySelect
          disabled
          onChange={() => {
            handleCountryChange();
            handleChangeInput();
          }}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) =>
          romaniaCountryCode &&
          prev.applicantCountryId !== next.applicantCountryId
        }
      >
        {({ getFieldValue }) => (
          <>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                romaniaCountryCode &&
                prev.applicantCountryId !== next.applicantCountryId
              }
            >
              {({ getFieldValue: getFieldValueNested }) =>
                getFieldValue('applicantCountryId') === romaniaCountryCode ? (
                  <>
                    <Form.Item
                      name="applicantCountyId"
                      label={t('entity.east.permit.applicantCountyId')}
                    >
                      <CountySelect
                        disabled
                        country={getFieldValueNested('applicantCountryId')}
                        onChange={handleCountyChange}
                      />
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.applicantCountyId !== next.applicantCountyId
                      }
                    >
                      {({ getFieldValue: getFieldValueNested2 }) => (
                        <Form.Item
                          name="applicantCityId"
                          label={t('entity.east.permit.applicantCityId')}
                        >
                          <CitySelect
                            disabled
                            county={getFieldValueNested2('applicantCountyId')}
                            onChange={handleChangeInput}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    name="applicantCity"
                    label={t('entity.east.permit.applicantCityId')}
                  >
                    <Input disabled />
                  </Form.Item>
                )
              }
            </Form.Item>

            <Form.Item
              name="applicantAddress"
              label={t('entity.east.permit.applicantAddress')}
            >
              <Input disabled onChange={handleChangeInput} />
            </Form.Item>
            <Form.Item
              name="applicantFiscalCode"
              label={t(
                `entity.east.permit.${
                  getFieldValue('applicantCountryId') === romaniaCountryCode ||
                  getFieldValue('isLegal')
                    ? 'transportFiscalCode'
                    : 'transportVAT'
                }`,
              )}
              onChange={handleChangeInput}
            >
              <Input disabled />
            </Form.Item>
            {form.getFieldValue('isLegal') && (
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) =>
                  romaniaCountryCode &&
                  prev.applicantCountryId !== next.applicantCountryId
                }
              >
                {({ getFieldValue: getFieldValueNested }) => (
                  <>
                    {getFieldValueNested('applicantCountryId') ===
                      romaniaCountryCode && (
                      <Form.Item
                        name="applicantTradeRegisterCode"
                        label={t(
                          'entity.east.permit.applicantTradeRegisterCode',
                        )}
                        onChange={handleChangeInput}
                      >
                        <Input disabled />
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.Item>
            )}
          </>
        )}
      </Form.Item>

      <Space size="large" align="baseline">
        <Typography.Text>
          {t('entity.east.permit.transportIsApplicant')}
        </Typography.Text>
        <Form.Item name="transportIsApplicant" valuePropName="checked">
          <Switch
            checkedChildren={t('entity._.true')}
            unCheckedChildren={t('entity._.false')}
            disabled={disabled}
            onChange={handleTransportIsApplicantChange}
          />
        </Form.Item>
      </Space>
    </>
  );
};

export default ApplicantSection;
