import React, { useMemo } from 'react';
import { Table } from 'antd';

const TableFormItemWrapper = ({ value, onChange, onTableChange, ...props }) => {
  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: value || [],
      onChange: (selectedRowKeys) => onChange(selectedRowKeys),
    }),
    [value, onChange],
  );

  return (
    <Table
      {...props}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      onChange={onTableChange}
    />
  );
};

export default TableFormItemWrapper;
