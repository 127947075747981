import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const values = ['DAYS30', 'DAYS90', 'DAYS365'];

const PeriodTypeSelect = ({ value, onSelect, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Select
      {...rest}
      style={{ width: '100%' }}
      value={value}
      onSelect={onSelect}
    >
      {values.map((row) => (
        <Select.Option value={row} key={row}>
          {t(`entity.east.permit.periodTypes.${row.toLowerCase()}`)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PeriodTypeSelect;
