import React from 'react';
import { Row, Col, Tabs } from 'antd';

import { Empty } from '../layouts';

import LoginForm from '../components/auth/LoginForm';
import TitleLogo from '../components/TitleLogo';

const Login = () => (
  <Empty>
    <TitleLogo />
    <Row>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 16, offset: 4 }}
        md={{ span: 12, offset: 6 }}
        lg={{ span: 8, offset: 8 }}
        xl={{ span: 6, offset: 9 }}
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Login" key="1">
            <LoginForm />
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </Row>
  </Empty>
);

export default Login;
