import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../../hooks/useDatasource';

import Column from '../../../helpers/Columns';
import {
  findAll as findAllUsers,
  remove,
} from '../../../services/admin/users/users';
import { findAll as findAllCountries } from '../../../services/admin/geo/countries';
import { findAll as findAllCounties } from '../../../services/admin/geo/counties';
import { findAll as findAllCities } from '../../../services/admin/geo/cities';
import { findAllActiveSubdivisions } from '../../../services/admin/users/subdivisions';

import EditItemIcon from '../../buttons/EditItemIcon';
import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import AddEntityButton from '../../buttons/AddEntityButton';

import useDictionaries from '../../../hooks/useDictionaries';
import AuthContext, { hasPermission } from '../../auth';
import useCountryCode from '../../../hooks/useCountryCode';

const dictionaries = {
  countryList: findAllCountries,
  countyList: findAllCounties,
  cityList: findAllCities,
  subdivisionList: findAllActiveSubdivisions,
};

const UsersList = () => {
  const { user } = useContext(AuthContext);
  const { permissions } = user;
  const romaniaCountryCode = useCountryCode('ROM');
  const [{ countryList, countyList, cityList, subdivisionList }] =
    useDictionaries(dictionaries);
  const { t } = useTranslation();

  const statusOptions = useMemo(
    () => ({
      content: [
        { id: 'CREATED', name: t('entity.admin.user.accountStatus.created') },
        { id: 'ACTIVE', name: t('entity.admin.user.accountStatus.active') },
        { id: 'DELETED', name: t('entity.admin.user.accountStatus.deleted') },
      ],
      error: false,
      loading: false,
    }),
    [t],
  );

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAllUsers);

  const columns = useMemo(
    () => [
      Column.text('email', t('entity.admin.user.email'), {
        width: 300,
        filter: true,
      }),
      Column.text('fullname', t('entity.admin.user.fullname'), {
        width: 300,
        filter: true,
      }),
      Column.text('role', t('entity.admin.user.role'), {
        width: 200,
        filter: true,
      }),
      Column.bool('legal', t('entity.admin.user.legal._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.admin.user.legal.legal'),
          t('entity.admin.user.legal.normal'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.text('personalCode', t('entity.admin.user.personalCode'), {
        width: 200,
        filter: true,
      }),
      Column.dictionary(
        'countryId',
        t('entity.admin.user.countryId'),
        countryList,
        {
          width: 200,
          filter: true,
        },
      ),
      {
        title: t('entity.admin.subdivision._singular'),
        dataIndex: 'countyId',
        key: 'countyId',
        width: 150,
        render: (value) => {
          const foundSub = subdivisionList.content.filter(
            (s) => s.counties && s.counties.includes(value),
          );
          return foundSub && foundSub[0] ? foundSub[0].name : '';
        },
      },
      Column.dictionary(
        'countyId',
        t('entity.admin.user.countyId'),
        countyList,
        {
          width: 200,
        },
      ),

      {
        title: t('entity.admin.user.cityId'),
        key: 'cityId',
        width: 150,
        render: (value) => {
          const { countryId, cityId, city } = value;
          return countryId !== romaniaCountryCode
            ? city
            : cityList.content.find((c) => c.id === cityId)?.name;
        },
      },
      Column.text('address', t('entity.admin.user.address'), {
        width: 200,
        filter: true,
      }),
      Column.text('phone', t('entity.admin.user.phone'), {
        width: 200,
        filter: true,
      }),
      Column.text('legalFullName', t('entity.admin.user.legalFullName'), {
        width: 200,
        filter: true,
      }),
      Column.date('createdAt', t('entity._.createdAt'), {
        width: 250,
      }),
      Column.text('createdBy', t('entity._.createdBy'), {
        width: 150,
      }),
      Column.date('updatedAt', t('entity._.updatedAt'), {
        width: 250,
      }),
      Column.text('updatedBy', t('entity._.updatedBy'), {
        width: 150,
      }),
      Column.dictionary(
        'status',
        t('entity.admin.user.status'),
        statusOptions,
        {
          width: 200,
          filter: true,
        },
      ),
      Column.actions(
        t('table.actions'),
        (record) =>
          (hasPermission(permissions, 'SYS_SUPERUSER') ||
            record.modifiebleByCnair) && (
            <span style={{ textAlign: 'right' }}>
              <EditItemIcon path={`/admin/users/users/${record.id}`} />
              <DeleteItemIcon
                title={t('entity.admin.user._delete', record)}
                message={t('entity.admin.user._deleted', record)}
                item={record}
                action={remove}
                reload={reload}
              />
            </span>
          ),
      ),
    ],
    [
      t,
      countryList,
      countyList,
      cityList,
      statusOptions,
      subdivisionList.content,
      romaniaCountryCode,
      permissions,
      reload,
    ],
  );
  return (
    <>
      <PageHeader
        title={t('entity.admin.user._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.admin.user._singular')}
            path="/admin/users/users/new"
          />,
        ]}
      />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 500 }}
      />
    </>
  );
};

export default UsersList;
