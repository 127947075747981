import React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';

const values = ['CAR', 'TRAILER', 'SEMITRAILER'];

const VehicleTypeSelect = ({ value, onSelect, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Select
      {...rest}
      style={{ width: '100%' }}
      value={value}
      onSelect={onSelect}
    >
      {values.map((row) => (
        <Select.Option value={row} key={row}>
          {t(`entity.east.permit.vehicleTypes.${row.toLowerCase()}`)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default VehicleTypeSelect;
