import React from 'react';

import { Default } from '../../../layouts';

import { useEditPage } from '../../../hooks/useEditPage';

import { findOne, create, update } from '../../../services/admin/geo/counties';

import EditCountyForm from '../../../components/admin/geo/EditCountyForm';

const initial = { countryId: 181 };

const EditCounty = () => {
  const [county, handleSubmit, handleCancel, errors] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/geo',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {county !== undefined && (
        <EditCountyForm
          county={county}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </Default>
  );
};

export default EditCounty;
