import { genericService, post, get, put } from '../../../helpers/api';

export const SERVICE_URI = '/users';
export const BASE_URI = '/v1/users';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const register = (user) =>
  post(`${SERVICE_URI}${BASE_URI}/register`, user);

export const forgot = ({ email, recaptcha }) =>
  post(`${SERVICE_URI}${BASE_URI}/reset`, { email, recaptcha });

export const reset = (token, payload) =>
  post(`${SERVICE_URI}${BASE_URI}/reset/${token}`, payload);

export const getCurrent = () => get(`${SERVICE_URI}${BASE_URI}/current`);

export const updateCurrent = (user) =>
  put(`${SERVICE_URI}${BASE_URI}/current`, user);
