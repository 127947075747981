import React, { useMemo } from 'react';

import { Button, Upload } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ImportButton = ({ path, entity }) => {
  const { t } = useTranslation();

  const props = useMemo(
    () => ({
      name: 'file',
      action: path,
      fileList: [],
      withCredentials: true,
    }),
    [path],
  );

  return (
    <div style={{ display: 'inline-block' }}>
      <Upload {...props}>
        <Button icon={<ImportOutlined />}>
          {t('actions.import')}
          {entity}
        </Button>
      </Upload>
    </div>
  );
};

export default ImportButton;
