import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { notification } from 'antd';

import { Clean } from '../layouts';

import { register } from '../services/admin/users/users';

import RegisterForm from '../components/auth/RegisterForm';

const Register = () => {
  const history = useHistory();

  const [errors, setErrors] = useState(null);

  const handleSubmit = useCallback(
    (value) => {
      setErrors(null);

      register(value).then(
        () => {
          notification.success({
            message: 'Contul de utilizator a fost creat cu succes',
          });
          history.goBack();
        },
        (err) => {
          const { inner } = err || {};

          setErrors(inner);

          notification.error({
            message: 'Eroare la create Cont de utilizator',
            description: inner?._,
          });
        },
      );
    },
    [history],
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Clean>
      <RegisterForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        errors={errors}
      />
    </Clean>
  );
};

export default Register;
