import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const ExportEntitiesButton = ({ downloadFiles, params }) => {
  const { t } = useTranslation();
  return (
    <Button
      icon={<DownloadOutlined />}
      onClick={() =>
        downloadFiles({
          ...params,
          criterias: {
            ...params,
          },
        })
      }
    >
      {t('actions.download')}
    </Button>
  );
};

export default ExportEntitiesButton;
