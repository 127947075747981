import React, { useMemo } from 'react';

import { Form, Radio } from 'antd';

import useFormContext from '../../../../hooks/useFormContext';
import useDefaultValues from '../../../../hooks/useDefaultValues';

const defaultValues = {
  accept: null,
};

const TermsTab = ({ disabled }) => {
  const form = useFormContext();
  useDefaultValues(form, defaultValues);

  const options = useMemo(
    () => [
      { label: 'Am citit si accept conditiile de mai sus', value: true },
      { label: 'Nu sunt de acord', value: false },
    ],
    [],
  );

  return (
    <>
      <div style={{ height: '400px', overflow: 'auto', margin: '4rem 0' }}>
        <p>
          SUBSEMNATUL {form.getFieldValue('applicantName')} AM LUAT CUNOSTINTA
          CU PRIVIRE LA:
        </p>
        <p>
          Dupa afisarea sumei de plata, clientul poate accepta plata sau poate
          anula solicitarea.
        </p>

        <p>
          Odata cu acceptarea platii, prin bifarea campului “Plateste” clientul
          se angajeaza definitiv si irevocabil sa vireze suma reprezentand
          tariful suplimentar de utilizare in contul C.N.A.I.R. S.A. Acceptarea
          nu mai poate fi anulata.
        </p>

        <p>
          Clientul trebuie sa efectueze plata pana cel tarziu ora 24.00 a zilei
          anterioare primei zile de valabilitate a AST.
        </p>

        <p>
          In cazul neefectuarii platii, solicitarea se transforma in estimare si
          clientul va fi debitat cu echivalentul in lei a sumei de 50 Euro (TVA
          inclus).
        </p>

        <p>
          In cazul in care are plati neachitate, clientul nu va mai putea initia
          alta solicitare, pana la achitarea sumelor datorate catre C.N.A.I.R.
          S.A.
        </p>

        <p>
          C.N.A.I.R. S.A. isi rezerva dreptul de a actiona prin toate caile
          legale pentru recuperarea sumelor neachitate.
        </p>

        <p>
          Circulația în zilele de sâmbătă, duminică, în zilele premergătoare
          zilelor de sărbătoare legală şi în zilele de sărbătoare legală, între
          orele 6.00 și 22.00 este permisă numai dacă în AST este înscrisă
          această posibilitate;
        </p>

        <p>
          Vehiculele cu depasiri pot circula pe drumurile publice numai daca:
        </p>

        <p>a) sunt admise in circulatie;</p>

        <p>
          b) corespund din punct de vedere al starii tehnice si al cerintelor de
          siguranta a circulatiei;
        </p>
        <p>c) nu depasesc masele maxime admise constructiv ale vehiculelor;</p>
        <p>
          d) detin AST eliberata de administratorul drumului pe care circula si
          respecta prevederile inscrise in AST;
        </p>
        <p>
          Titularul AST este operatorul de transport, care poartă întreaga
          responsabilitate a circulației vehiculului cu depășiri și are
          obligația de a respecta condițiile impuse prin AST ;
        </p>
        <p>
          O copie a AST trebuie să se afle la bordul vehiculului cu depășiri pe
          întreaga perioadă de circulație, fiind obligatorie prezentarea
          acesteia la controalele efectuate de personalul abilitat ;
        </p>
        <p>
          AST este valabilă pentru sectoarele de drum de interes național si
          autostrazi aflate in administrarea C.N.A.I.R. S.A.
        </p>
        <p>
          AST nu este valabila pe sectoarele de drumuri naționale situate în
          intravilanul municipiilor/reședințelor de județ, pe drumurile
          judetene, pe drumurile comunale sau pe alte categorii de drumuri.
          Pentru circulația pe aceste categorii de drumuri, operatorul de
          transport va solicita AST de la administratorul drumului respectiv.
        </p>
        <p>
          AST este valabilă pentru numărul de circulație al vehiculului cu
          depășiri, inclusiv cel al vehiculului de rezervă înscrise în aceasta,
          traseul, sensul, perioada și caracteristicile autorizate înscrise, în
          condițiile stabilite de emitentul acesteia.
        </p>
        <p>
          Circulația vehiculelor cu depasiri este interzisa în zilele de
          sâmbătă, duminică, în zilele premergătoare zilelor de sărbătoare
          legală şi în zilele de sărbătoare legală, între orele 6.00 și 22.00.
        </p>
        <p>
          Circulația vehiculelor cu depasiri este interzisa in conditii de
          vizibilitate redusa sub 100 m, din cauza cetii, ploii sau ninsorii
          abundente ori cand partea carosabila este acoperita cu polei, gheata
          sau zapada, precum si in cazul unor fenomene naturale ce afecteaza
          siguranta circulatiei, semnalate prin instituirea de catre
          institutiile abilitate uneia din avertizarile de cod galben,
          portocalui sau rosu.
        </p>
        <p>
          Operatorul de transport (Titularul AST) are obligația de a se informa
          cu privire la eventualele restricții de circulație instituite/
          existente pe traseul ce urmează a fi parcurs de vehiculul cu depășiri.
        </p>
        <p>
          Circulația vehiculului cu depășiri se va face cu respectarea
          restricțiilor de circulație existente pe traseul ce urmează a fi
          parcurs de vehiculul cu depășiri.
        </p>
        <p>
          Responsabilitatea fixării și ancorării încărcăturii pe vehicul revine
          operatorului de transport, acesta fiind răspunzător de prejudiciile şi
          accidentele produse din cauza deplasării necontrolate a încărcăturii
          în timpul deplasarii vehiculului.
        </p>
        <p>
          Pentru subtraversarea conductelor, rețelelor aeriene şi a altor
          instalații, operatorul de transport are obligația de a obține, în
          prealabil parcurgerii traseului, avizele unităților care le
          administrează, în vederea ridicării, demontării și remontării
          acestora, după caz.
        </p>
        <p>
          Traversarea de către vehiculele cu depășiri a pasajelor la nivel cu
          calea ferată, unde există semnalizare automată, se va efectua în
          maximum 30 de secunde; pentru cazurile care impun depășirea acestui
          timp de trecere operatorul de transport va obține, în prealabil,
          avizul administratorului infrastructurii feroviare.
        </p>
        <p>
          Pentru traversarea pasajelor la nivel cu calea ferată operatorul de
          transport va obține, în prealabil, aprobarea administratorului
          infrastructurii feroviare, în cazul vehiculelor cu lățimi mai mari
          decât lățimea parții carosabile din zona de trecere la nivel cu calea
          ferată, înălțimi mai mari de 4,0 m (în cazul liniilor electrificate),
          lungimi mai mari de 30,0 m;
        </p>
        <p>
          Operatorul de transport va respecta toate condițiile de trecere pe
          pasajele la nivel cu calea ferată stabilite de administratorul
          infrastructurii feroviare;
        </p>
        <p>
          Circulația vehiculelor cu depășiri ale masei totale și/sau ale maselor
          pe axe maxime admise, pe poduri, pe pasaje superioare sau viaducte se
          va efectua cu viteza maximă de 10 km/h, fără opriri, șocuri, pe banda
          din dreapta în sensul de mers, dacă nu sunt impuse, prin AST, alte
          condiții speciale de traversare.
        </p>
        <p>
          Vehiculele a căror masă totală este mai mare de 80,0 tone, vor circula
          pe poduri, pe pasaje superioare sau viaducte, cu întreruperea
          obligatorie, temporară și locală a circulației de către politia
          rutieră, pentru traversarea singulară a lucrării de artă, pe axul
          acesteia.
        </p>
        <p>
          Vehiculul care circulă cu depășirea dimensiunilor și/sau maselor
          maxime admise trebuie să fie echipat în conformitate cu prevederile
          Normelor privind autorizarea şi desfășurarea circulației vehiculelor
          rutiere cu mase și/sau dimensiuni ce depășesc masele şi/sau
          dimensiunile maxime admise prevăzute în Ordonanța Guvernului nr.
          43/1997 privind regimul drumurilor.
        </p>
        <p>
          Însoțirea vehiculelor care circulă cu depășiri se realizează numai de
          operatori economici specializați, autovehicule de însoțire și personal
          specializat.
        </p>
        <p>
          Autovehiculul de însoțire se dotează, obligatoriu, în conformitate cu
          prevederile Normelor privind autorizarea și desfășurarea circulației
          vehiculelor rutiere cu mase şi/sau dimensiuni ce depășesc masele
          și/sau dimensiunile maxime admise prevăzute în Ordonanța Guvernului
          nr. 43/1997 privind regimul drumurilor.
        </p>
        <p>
          Personalul specializat, atestat în vederea însoțirii vehiculelor cu
          depășiri are obligațiile stabilite prin Normele privind autorizarea și
          desfășurarea circulației vehiculelor rutiere cu mase și/sau dimensiuni
          ce depășesc masele și/sau dimensiunile maxime admise prevăzute în
          Ordonanța Guvernului nr. 43/1997 privind regimul drumurilor.
        </p>
      </div>
      <Form.Item name="accept">
        <Radio.Group
          options={options}
          optionType="button"
          buttonStyle="solid"
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};

export default TermsTab;
