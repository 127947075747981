import { useState, useEffect } from 'react';
import { findAll } from '../services/admin/geo/countries';

export default function useCountryCode(code) {
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    findAll({ criterias: { code } }).then(
      (res) =>
        res && res.totalElements === 1 && setCountryCode(res.content[0].id),
    );
  }, [code]);

  return countryCode;
}
