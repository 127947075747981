import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Col, notification, Row, Tabs } from 'antd';

import { Empty } from '../layouts';

import ForgotPasswordForm from '../components/auth/ForgotPasswordForm';
import ResetPasswordForm from '../components/auth/ResetPasswordForm';
import TitleLogo from '../components/TitleLogo';
import { forgot, reset } from '../services/admin/users/users';

const Register = () => {
  const history = useHistory();
  const { token } = useParams();
  const { t } = useTranslation();

  const [errors, setErrors] = useState(null);

  const handleSubmit = useCallback(
    (value) => {
      (token
        ? reset(token, value).then(() => {
            notification.success({
              message: t('messages.password.changed'),
              duration: 5000,
            });
            setTimeout(() => history.push('/'), 5000);
          })
        : forgot(value).then(() => {
            notification.success({
              message: t('messages.password.reset'),
              duration: 5000,
            });
            setTimeout(() => history.push('/'), 5000);
          })
      ).catch((err) => setErrors(err?.inner || {}));
    },
    [history, t, token],
  );

  const handleCancel = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <Empty>
      <TitleLogo />
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 16, offset: 4 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 8, offset: 8 }}
          xl={{ span: 6, offset: 9 }}
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={t('actions.resetPassword')} key="1">
              {token ? (
                <ResetPasswordForm
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                  errors={errors}
                />
              ) : (
                <ForgotPasswordForm
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                  errors={errors}
                />
              )}
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Empty>
  );
};

export default Register;
