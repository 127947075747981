import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Row, Col, Space } from 'antd';

import useDatasource from '../../../hooks/useDatasource';

import { findAll } from '../../../services/admin/users/permissions';

import Column from '../../../helpers/Columns';

import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';
import TableFormItemWrapper from '../../TableFormItemWrapper';
import useFormErrors from '../../../hooks/useFormErrors';

const EditRoleForm = ({ role, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  useFormErrors(form, errors);

  const columns = useMemo(
    () => [
      Column.text('name', t('entity.admin.permission.name'), {
        width: 300,
      }),
      Column.text('description', t('entity.admin.permission.description')),
    ],
    [t],
  );

  const { loading, pagination, content, handleChange } = useDatasource(findAll);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={role}
        onFinish={onSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={10} lg={8} xl={8}>
            <Form.Item label={t('entity.admin.role.name')} name="name">
              <Input />
            </Form.Item>
            <Form.Item
              label={t('entity.admin.role.description')}
              name="description"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={14} lg={16} xl={16}>
            <Form.Item name="permissions" noStyle>
              <TableFormItemWrapper
                columns={columns}
                rowKey="name"
                loading={loading}
                pagination={pagination}
                dataSource={content}
                onTableChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <SaveButton />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditRoleForm;
