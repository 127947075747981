import React, { useEffect } from 'react';

import { Form, Input, Row, Col, Space } from 'antd';
import PhoneInput from 'react-phone-number-input';

import { useTranslation } from 'react-i18next';
import { FormContextProvider } from '../../../hooks/useFormContext';

import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';

import useFormErrors from '../../../hooks/useFormErrors';
import useCountryCode from '../../../hooks/useCountryCode';
import UserAdressForm from './UserAdressForm';

const NaturalPersonFragment = ({ romaniaCountryCode }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={t('entity.admin.user.email')} name="email">
            <Input disabled />
          </Form.Item>

          <Form.Item label={t('entity.admin.user.fullname')} name="fullname">
            <Input />
          </Form.Item>
          <Form.Item
            label={t('entity.admin.user.personalCode')}
            name="personalCode"
          >
            <Input disabled />
          </Form.Item>

          <UserAdressForm romaniaCountryCode={romaniaCountryCode} />

          <Form.Item label={t('entity.admin.user.phone')} name="phone">
            <PhoneInput defaultCountry="RO" withCountryCallingCode />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const LegalPersonFragment = ({ romaniaCountryCode }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item
          label={t('entity.admin.user.economicOperator')}
          name="legalFullName"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('entity.admin.user.fiscalCode')}
          name="personalCode"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t('entity.admin.user.tradeRegisterCode')}
          name="tradeRegisterCode"
        >
          <Input />
        </Form.Item>

        <UserAdressForm romaniaCountryCode={romaniaCountryCode} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item label={t('entity.admin.user.email')} name="email">
          <Input disabled />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.legalPhone')} name="phone">
          <PhoneInput defaultCountry="RO" withCountryCallingCode />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.bank')} name="bank">
          <Input />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.iban')} name="iban">
          <Input />
        </Form.Item>

        <Form.Item
          label={t('entity.admin.user.fullnameLegalRep')}
          name="fullname"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('entity.admin.user.phoneLegalRep')}
          name="legalPhone"
        >
          <PhoneInput defaultCountry="RO" withCountryCallingCode />
        </Form.Item>
      </Col>
    </Row>
  );
};

const EditUserInfoForm = ({ user, onSubmit, onCancel, errors }) => {
  const [form] = Form.useForm();
  useFormErrors(form, errors);

  useEffect(() => {
    form.setFieldsValue(user);
  }, [user, form]);

  const romaniaCountryCode = useCountryCode('ROM');

  return (
    <>
      <FormContextProvider form={form}>
        <Form
          form={form}
          layout="vertical"
          initialValues={user}
          onFinish={onSubmit}
        >
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.legal !== next.legal}
          >
            {({ getFieldValue }) =>
              getFieldValue('legal') ? (
                <LegalPersonFragment romaniaCountryCode={romaniaCountryCode} />
              ) : (
                <NaturalPersonFragment
                  romaniaCountryCode={romaniaCountryCode}
                />
              )
            }
          </Form.Item>
          <Form.Item>
            <Space>
              <SaveButton />
              <CancelButton onCancel={onCancel} />
            </Space>
          </Form.Item>
        </Form>
      </FormContextProvider>
    </>
  );
};

export default EditUserInfoForm;
