import React, { useEffect, useReducer } from 'react';

import { Row, Col } from 'antd';

import useFormContext from '../../../../hooks/useFormContext';
// import useDefaultValues from '../../../../hooks/useDefaultValues';

import ApplicantSection from './ApplicantSection';
import CarrierSection from './TransportSection';
import ValabilitySection from './ValabilitySection';
import { getCurrent } from '../../../../services/admin/users/users';
import useCountryCode from '../../../../hooks/useCountryCode';

const updateForm = (form, defaultValues) => {
  if (form) {
    const values = form.getFieldsValue(Object.keys(defaultValues));
    form.setFieldsValue(
      Object.keys(defaultValues).reduce(
        (acc, key) => ({
          [key]: values[key] || defaultValues[key],
          ...acc,
        }),
        {},
      ),
    );
  }
};

const ApplicantTab = ({ disabled }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const form = useFormContext();

  useEffect(() => {
    getCurrent()
      .then((userInfo) => ({
        requestType: 'SINGLE',
        transportIsApplicant: false,
        routeType: 'ALL',
        periodType: 'DAYS30',
        applicantName: userInfo.legal ? '' : userInfo.fullname,
        applicantPersonalCode: userInfo.legal ? '' : userInfo.personalCode,
        applicantPhone: userInfo.legal ? '' : userInfo.phone,
        applicantEmail: userInfo.email,
        applicantCountryId: userInfo.countryId,
        applicantCountyId: userInfo.countyId,
        applicantCityId: userInfo.cityId,
        applicantCity: userInfo.city,
        applicantAddress: userInfo.address || '',
        applicantLegalName: userInfo.legal
          ? userInfo.legalFullName
          : userInfo.fullname,
        applicantTradeRegisterCode: userInfo.legal
          ? userInfo.tradeRegisterCode
          : '',
        applicantFiscalCode: userInfo.personalCode || '',
        isLegal: userInfo.legal,
      }))
      .then((userInfo) => updateForm(form, userInfo))
      .then(forceUpdate); // TODO: Refactor this code
  }, [forceUpdate, form]);

  const romaniaCountryCode = useCountryCode('ROM');

  return (
    <>
      <Row gutter={{ md: 32, lg: 64 }}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <ApplicantSection
            disabled={disabled}
            romaniaCountryCode={romaniaCountryCode}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CarrierSection
            disabled={disabled}
            romaniaCountryCode={romaniaCountryCode}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <ValabilitySection disabled={disabled} />
        </Col>
      </Row>
    </>
  );
};

export default ApplicantTab;
