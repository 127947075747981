import React from 'react';

import './AppLoader.scss';

const bars = '-'
  .repeat(16)
  .split('')
  .map((_val, idx) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={idx} className={`apploader__bar apploader__bar--${idx}`} />
  ));

const AppLoader = () => <div className="apploader">{bars}</div>;

export default AppLoader;
