import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Result } from 'antd';

import { useRouteParams } from '../../../hooks/useRouteParams';

const PaymentStatusTab = ({ paid, message }) => {
  const history = useHistory();

  const { id } = useRouteParams();

  return (
    <>
      <Result
        status={paid ? 'success' : 'error'}
        title={paid ? 'Payment complete' : 'Payment Failed'}
        subTitle={paid ? '' : message}
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => history.push(`${id}`)}
          >
            Ok
          </Button>,
        ]}
      />
    </>
  );
};

export default PaymentStatusTab;
