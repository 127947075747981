import React, { useMemo, useCallback } from 'react';
import moment from 'moment';

import { DatePicker } from 'antd';

const LocalDatePicker = ({ value, onChange, ...rest }) => {
  const localValue = useMemo(
    () => (value ? moment(value).startOf('day') : null),
    [value],
  );

  const handleOnChange = useCallback(
    (_, str) => {
      onChange(str);
    },
    [onChange],
  );

  return <DatePicker value={localValue} onChange={handleOnChange} {...rest} />;
};

export default LocalDatePicker;
