import { useEffect, useState, useCallback } from 'react';

const init = (definition) =>
  Object.entries(definition).reduce(
    (acc, [key]) => ({
      ...acc,
      [key]: { content: [], loading: true, error: false },
    }),
    {},
  );

const load = (definition) =>
  Promise.all(
    Object.entries(definition).map(([key, fetcher]) =>
      fetcher({ pageSize: 5000 })
        .then((result) => ({
          [key]: { content: result.content, loading: false, error: false },
        }))
        .catch((err) => {
          console.error(err);
          return { [key]: { content: [], loading: false, error: true } };
        }),
    ),
  ).then((res) => res.reduce((acc, val) => ({ ...acc, ...val }), {}));

export default function useDictionaries(definition) {
  const [dictionaries, setDictionaries] = useState(init(definition));

  const reload = useCallback(() => {
    Promise.resolve()
      .then(() => init(definition))
      .then(() => load(definition))
      .then((res) => setDictionaries(res));
  }, [definition]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [dictionaries, reload];
}
