import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Row, Col, Select } from 'antd';

import useFormContext from '../../../hooks/useFormContext';

import CountrySelect from '../../CountrySelect';
import CountySelect from '../../CountySelect';
import CitySelect from '../../CitySelect';

const UserAdressForm = ({ romaniaCountryCode, isCNAIR, subdivisions }) => {
  const form = useFormContext();
  const { t } = useTranslation();
  const [reload, setReload] = useState(0);

  const handleCountryChange = useCallback(() => {
    setReload((prev) => !prev);
    if (form.getFieldValue(['countryId'])) {
      form.setFieldsValue({
        city: null,
        cityId: null,
        countyId: null,
        subdivision: null,
      });
    }
  }, [form]);

  const handleCountyChange = useCallback(() => {
    setReload((prev) => !prev);
    if (form.getFieldValue(['countryId'])) {
      form.setFieldsValue({
        cityId: null,
      });

      if (isCNAIR) {
        const foundSub = subdivisions?.content?.filter(
          (s) =>
            s?.counties &&
            s?.counties?.includes(form.getFieldValue(['countyId'])),
        );
        form.setFieldsValue({
          subdivision: foundSub && foundSub[0] ? foundSub[0].name : '',
        });
      }
    }
  }, [form, isCNAIR, subdivisions?.content]);

  return (
    <>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => prev.countryId !== next.countryId}
      >
        {({ getFieldValue }) => (
          <>
            <Form.Item
              label={t('entity.admin.user.countryId')}
              name="countryId"
            >
              <CountrySelect onChange={handleCountryChange} />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                romaniaCountryCode && prev.countryId !== next.countryId
              }
            >
              {getFieldValue('countryId') === romaniaCountryCode &&
                (isCNAIR ? (
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="countyId"
                        label={t('entity.admin.user.countyId')}
                      >
                        <CountySelect
                          country={getFieldValue('countryId')}
                          onChange={handleCountyChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="subdivision"
                        label={t('entity.admin.subdivision._singular')}
                      >
                        <Select style={{ minWidth: '120px' }} disabled>
                          {subdivisions.content.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  <Form.Item
                    name="countyId"
                    label={t('entity.admin.user.countyId')}
                  >
                    <CountySelect
                      country={getFieldValue('countryId')}
                      onChange={handleCountyChange}
                    />
                  </Form.Item>
                ))}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.countyId !== next.countyId}
            >
              {getFieldValue('countryId') !== romaniaCountryCode ? (
                <Form.Item
                  name="city"
                  label={t('entity.admin.user.cityId')}
                  shouldUpdate={reload}
                >
                  <Input
                    onChange={() =>
                      form.setFieldsValue({
                        cityId: null,
                      })
                    }
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="cityId"
                  label={t('entity.admin.user.cityId')}
                  shouldUpdate={reload}
                >
                  <CitySelect
                    county={form.getFieldValue('countyId')}
                    onSelect={() =>
                      form.setFieldsValue({
                        city: null,
                      })
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          </>
        )}
      </Form.Item>
      <Form.Item label={t('entity.admin.user.address')} name="address">
        <Input />
      </Form.Item>
    </>
  );
};

export default UserAdressForm;
