import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import useDatasource from '../../../hooks/useDatasource';

import Column from '../../../helpers/Columns';
import { findAll, remove } from '../../../services/admin/geo/cities';

import EditItemIcon from '../../buttons/EditItemIcon';
import DeleteItemIcon from '../../buttons/DeleteItemIcon';
import AddEntityButton from '../../buttons/AddEntityButton';

const CitiesList = () => {
  const { t } = useTranslation();

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('code', t('entity.admin.city.code'), {
        width: 100,
        filter: true,
      }),
      Column.text('name', t('entity.admin.city.name'), {
        width: 300,
        filter: true,
      }),
      Column.text('county', t('entity.admin.city.county'), {
        width: 300,
        filter: true,
      }),
      Column.date('createdAt', t('entity._.createdAt'), {
        width: 250,
      }),
      Column.text('createdBy', t('entity._.createdBy'), {
        width: 150,
      }),
      Column.date('createdAt', t('entity._.updatedAt'), {
        width: 250,
      }),
      Column.text('createdBy', t('entity._.updatedBy'), {
        width: 150,
      }),
      Column.bool('deleted', t('entity._.deleted._'), {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <EditItemIcon path={`/admin/geo/cities/${record.id}`} />
          <DeleteItemIcon
            title={t('entity.admin.city._delete', record)}
            message={t('entity.admin.city._deleted', record)}
            item={record}
            action={remove}
            reload={reload}
          />
        </span>
      )),
    ],
    [t, reload],
  );

  return (
    <>
      <PageHeader
        title={t('entity.admin.city._plural')}
        extra={[
          <AddEntityButton
            key="new"
            entity={t('entity.admin.city._singular')}
            path="/admin/geo/cities/new"
          />,
        ]}
      />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default CitiesList;
