import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const values = [false, true];

const BooleanSelect = ({ value, onSelect, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Select
      {...rest}
      style={{ width: '100%' }}
      value={value}
      onSelect={onSelect}
    >
      {values.map((row) => (
        <Select.Option value={row} key={row.toString()}>
          {t(`entity._.${row.toString()}`)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BooleanSelect;
