import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form, Input, Row, Col, Space, Select, Radio } from 'antd';
import PhoneInput from 'react-phone-number-input';

import { FormContextProvider } from '../../../hooks/useFormContext';

import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';

import {
  findAll as findAllRoles,
  findOne,
} from '../../../services/admin/users/roles';
import { findAllActiveSubdivisions } from '../../../services/admin/users/subdivisions';
import useDictionaries from '../../../hooks/useDictionaries';
import FileInput from '../../FileInput';
import useFormErrors from '../../../hooks/useFormErrors';
import useCountryCode from '../../../hooks/useCountryCode';
import UserAdressForm from './UserAdressForm';

const dictionaries = {
  roles: findAllRoles,
  subdivisions: findAllActiveSubdivisions,
};

const NaturalPersonFragment = ({ disabled, romaniaCountryCode, isCNAIR }) => {
  const { t } = useTranslation();

  const [{ subdivisions }] = useDictionaries(dictionaries);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={t('entity.admin.user.email')} name="email">
            <Input disabled={disabled} />
          </Form.Item>
          <Form.Item label={t('entity.admin.user.fullname')} name="fullname">
            <Input />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.countryId !== next.countryId}
          >
            <>
              <Form.Item
                label={t('entity.admin.user.personalCode')}
                name="personalCode"
              >
                <Input />
              </Form.Item>
            </>
          </Form.Item>
          <Form.Item name="identityCard">
            <FileInput
              scope="usrdc"
              label={t('entity.admin.user.identityCard')}
            />
          </Form.Item>

          <UserAdressForm
            romaniaCountryCode={romaniaCountryCode}
            isCNAIR={isCNAIR}
            subdivisions={subdivisions}
          />

          <Form.Item label={t('entity.admin.user.phone')} name="phone">
            <PhoneInput defaultCountry="RO" withCountryCallingCode />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const LegalPersonFragment = ({ romaniaCountryCode, isCNAIR }) => {
  const { t } = useTranslation();
  const [{ subdivisions }] = useDictionaries(dictionaries);

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item
          label={t('entity.admin.user.economicOperator')}
          name="legalFullName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('entity.admin.user.fiscalCode')}
          name="personalCode"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('entity.admin.user.tradeRegisterCode')}
          name="tradeRegisterCode"
        >
          <Input />
        </Form.Item>
        <Form.Item name="identityCard">
          <FileInput
            scope="usrdc"
            label={t('entity.admin.user.legalIdentityCard')}
          />
        </Form.Item>

        <UserAdressForm
          romaniaCountryCode={romaniaCountryCode}
          isCNAIR={isCNAIR}
          subdivisions={subdivisions}
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item label={t('entity.admin.user.email')} name="email">
          <Input disabled />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.legalPhone')} name="phone">
          <PhoneInput defaultCountry="RO" withCountryCallingCode />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.bank')} name="bank">
          <Input />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.iban')} name="iban">
          <Input />
        </Form.Item>

        <Form.Item
          label={t('entity.admin.user.fullnameLegalRep')}
          name="fullname"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('entity.admin.user.phoneLegalRep')}
          name="legalPhone"
        >
          <PhoneInput defaultCountry="RO" withCountryCallingCode />
        </Form.Item>
      </Col>
    </Row>
  );
};

const EditUserForm = ({ user, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isNew, setIsNew] = useState(null);

  useEffect(() => {
    setIsNew(id === 'new');
  }, [id]);

  const [{ roles }] = useDictionaries(dictionaries);

  const [form] = Form.useForm();
  useFormErrors(form, errors);

  useEffect(() => {
    form.setFieldsValue(user);
  }, [user, form]);

  const [isCNAIR, setCNAIR] = useState(false);
  const romaniaCountryCode = useCountryCode('ROM');

  const legalOptions = useMemo(
    () =>
      [
        { label: t('entity.admin.user.simplePerson'), value: false },
        { label: t('entity.admin.user.legalPerson'), value: true },
      ].filter((o) => isNew || o.value === user.legal),
    [t, isNew, user],
  );

  const statusOptions = useMemo(
    () => [
      {
        label: t('entity.admin.user.accountStatus.created'),
        value: 'CREATED',
      },
      { label: t('entity.admin.user.accountStatus.active'), value: 'ACTIVE' },
      { label: t('entity.admin.user.accountStatus.deleted'), value: 'DELETED' },
    ],
    [t],
  );

  const handleRoleChange = useCallback(() => {
    findOne(form.getFieldValue('role')).then((r) => {
      setCNAIR(r.permissions.includes('CNAIR_CONTROL'));
    });
  }, [form]);

  return (
    <>
      <FormContextProvider form={form}>
        <Form
          form={form}
          layout="vertical"
          initialValues={user}
          onFinish={onSubmit}
        >
          <Space>
            <Form.Item name="legal">
              <Radio.Group
                disabled={user.id}
                options={legalOptions}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
            <Form.Item name="status">
              <Select
                style={{ minWidth: '120px' }}
                placeholder={t('entity.admin.user.status')}
              >
                {statusOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="role">
              <Select
                style={{ minWidth: '120px' }}
                allowClear
                placeholder={t('entity.admin.user.role')}
                onChange={handleRoleChange}
              >
                {roles.content.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.legal !== next.legal || prev.role !== next.role
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('legal') ? (
                <LegalPersonFragment
                  disabled={!!getFieldValue('id')}
                  romaniaCountryCode={romaniaCountryCode}
                  isCNAIR={isCNAIR}
                />
              ) : (
                <NaturalPersonFragment
                  disabled={!!getFieldValue('id')}
                  romaniaCountryCode={romaniaCountryCode}
                  isCNAIR={isCNAIR}
                />
              )
            }
          </Form.Item>
          <Form.Item>
            <Space>
              <SaveButton />
              <CancelButton onCancel={onCancel} />
            </Space>
          </Form.Item>
        </Form>
      </FormContextProvider>
    </>
  );
};

export default EditUserForm;
