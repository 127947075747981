import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Form,
  Radio,
  Row,
  Col,
  Divider,
  InputNumber,
  Input,
  Space,
  Button,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import useFormContext from '../../../../hooks/useFormContext';

import DivisibleSelect from '../../../DivisibleSelect';
import useDefaultValues from '../../../../hooks/useDefaultValues';
import TransrouteSection from './TransrouteSection';

const defaultRowValue = {
  oversized: false,
  optionTotalMassDiv: false,
  optionLengthDiv: false,
  optionWidthDiv: false,
  optionHeightDiv: false,
  totalWeight: 0,
};

const defaultValues = {
  routeOptions: [
    {
      ...defaultRowValue,
    },
  ],
};

const RouteTab = ({ disabled }) => {
  const form = useFormContext();
  useDefaultValues(form, { ...defaultValues });

  const { t } = useTranslation();

  const axisGroups = useMemo(() => {
    const { vehicleType, vehicleAxis, trailerAxis } = form.getFieldsValue([
      'vehicleType',
      'vehicleAxis',
      'trailerAxis',
      'axisOptions',
    ]);

    const axisCount =
      (parseInt(vehicleAxis, 10) || 0) +
      (vehicleType === 'CAR' ? 0 : parseInt(trailerAxis, 10) || 0);

    return new Array(Math.ceil(axisCount / 6.0)).fill(null).map((_1, idx1) => {
      const from = idx1 * 6 + 1;
      const to = Math.min(from + 6 - 1, axisCount);
      const indexes = new Array(to - from + 1)
        .fill(null)
        .map((_2, idx2) => idx2 + from - 1);
      return [from, to, indexes];
    });
  }, [form]);

  const dimensions = useMemo(() => {
    const res = form.getFieldsValue([
      'allowedLengthR',
      'allowedLengthNR',
      'allowedLengthM',
      'allowedLengthP',
      'allowedWidthR',
      'allowedWidthNR',
      'allowedWidthM',
      'allowedWidthP',
      'allowedHeightR',
      'allowedHeightNR',
      'allowedHeightM',
      'allowedHeightP',
    ]);

    return res;
  }, [form]);
  useEffect(() => {
    if (form.getFieldValue('status') === 'ROUTE') {
      const axisLength = form
        .getFieldValue('axisOptions')
        ?.reduce((a, b) => a + (b.distance || 0), 0);
      form.setFieldsValue({
        routeOptions: [
          {
            oversized:
              axisLength > dimensions.allowedLengthR &&
              axisLength > dimensions.allowedLengthNR &&
              axisLength > dimensions.allowedLengthM &&
              axisLength > dimensions.allowedLengthP,
          },
        ],
      });
    }
  }, [form, dimensions]);

  const dimensionOptions = useMemo(
    () => [
      { label: t('Limita admisa'), value: false },
      { label: t('Gabarit depasit'), value: true },
    ],
    [t],
  );

  const handleOptionTotalMassDivChange = useCallback(
    (key, val) => {
      if (!val) {
        const { routeOptions } = form.getFieldsValue([`routeOptions`]);
        routeOptions[key] = {
          ...routeOptions[key],
          optionLengthDiv: false,
          optionWidthDiv: false,
          optionHeightDiv: false,
        };
        form.setFieldsValue({ routeOptions });
      }
    },
    [form],
  );

  const handleAxisWeightChange = useCallback(
    (key) => {
      const { routeOptions } = form.getFieldsValue([`routeOptions`]);
      const totalWeigth = routeOptions[key].axisWeight.reduce(
        (sum, val) => sum + (val || 0),
        0,
      );
      routeOptions[key].totalWeight = totalWeigth;
      form.setFieldsValue({ routeOptions });
    },
    [form],
  );
  const { requestType } = form.getFieldsValue(['requestType']);
  return (
    <>
      <Form.List name="routeOptions">
        {(fields, { add }) => (
          <>
            {fields.map(({ name, fieldKey }, idx) => (
              <div key={fieldKey} className="route">
                <Divider plain>Traseu {idx + 1}</Divider>
                <Row gutter={{ md: 32, lg: 64 }}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <TransrouteSection
                      name={name}
                      fieldKey={fieldKey}
                      disabled={disabled}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Divider plain>Dimensiuni</Divider>
                    <Row gutter={16}>
                      <Col span={16}>
                        <div className="ant-col ant-form-item-label">
                          <label>Lungime admisa (R / NR / M/ P) (metri)</label>
                        </div>
                        <Row gutter={0} style={{ marginBottom: '12px' }}>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedLengthR}
                            />
                          </Col>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedLengthNR}
                            />
                          </Col>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedLengthM}
                            />
                          </Col>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedLengthP}
                            />
                          </Col>
                        </Row>

                        <div className="ant-col ant-form-item-label">
                          <label>Latime admisa (R / NR / M/ P) (metri)</label>
                        </div>
                        <Row gutter={0} style={{ marginBottom: '12px' }}>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedWidthR}
                            />
                          </Col>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedWidthNR}
                            />
                          </Col>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedWidthM}
                            />
                          </Col>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedWidthP}
                            />
                          </Col>
                        </Row>

                        <div className="ant-col ant-form-item-label">
                          <label>Inaltime admisa (R / NR / M/ P) (metri)</label>
                        </div>
                        <Row gutter={0} style={{ marginBottom: '12px' }}>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedHeightR}
                            />
                          </Col>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedHeightNR}
                            />
                          </Col>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedHeightM}
                            />
                          </Col>
                          <Col span={6}>
                            <InputNumber
                              disabled
                              precision={2}
                              value={dimensions.allowedHeightP}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, next) =>
                            prev.routeOptions[fieldKey].oversized !==
                            next.routeOptions[fieldKey].oversized
                          }
                        >
                          {({ getFieldValue }) => (
                            <>
                              <Form.Item
                                name={[name, 'oversizedLength']}
                                fieldKey={[fieldKey, 'oversizedLength']}
                                label="Lungime (metri)"
                              >
                                <InputNumber
                                  disabled={
                                    !getFieldValue([
                                      'routeOptions',
                                      fieldKey,
                                      'oversized',
                                    ]) || disabled
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                name={[name, 'oversizedWidth']}
                                fieldKey={[fieldKey, 'oversizedWidth']}
                                label="Latime (metri)"
                              >
                                <InputNumber
                                  disabled={
                                    !getFieldValue([
                                      'routeOptions',
                                      fieldKey,
                                      'oversized',
                                    ]) || disabled
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                name={[name, 'oversizedHeight']}
                                fieldKey={[fieldKey, 'oversizedHeight']}
                                label="Inaltime (metri)"
                              >
                                <InputNumber
                                  disabled={
                                    !getFieldValue([
                                      'routeOptions',
                                      fieldKey,
                                      'oversized',
                                    ]) || disabled
                                  }
                                />
                              </Form.Item>
                            </>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      name={[name, 'oversized']}
                      fieldKey={[fieldKey, 'oversized']}
                      style={{ textAlign: 'center' }}
                    >
                      <Radio.Group
                        disabled={disabled}
                        options={dimensionOptions}
                        optionType="button"
                        buttonStyle="solid"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Divider plain>Caracteristici Vehicul Incarcat</Divider>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'optionTotalMassDiv']}
                          fieldKey={[fieldKey, 'optionTotalMassDiv']}
                          label="Masa totala"
                        >
                          <DivisibleSelect
                            disabled={disabled}
                            onChange={(val) =>
                              handleOptionTotalMassDivChange(fieldKey, val)
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name={[name, 'totalWeight']}
                          fieldKey={[fieldKey, 'totalWeight']}
                          label="Masa totala calculata"
                        >
                          <InputNumber disabled precision={2} />
                        </Form.Item>

                        <Form.Item
                          name={[name, 'goods']}
                          fieldKey={[fieldKey, 'goods']}
                          label="Descriere marfa"
                        >
                          <Input.TextArea disabled={disabled} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, next) =>
                            prev.routeOptions[fieldKey].optionTotalMassDiv !==
                            next.routeOptions[fieldKey].optionTotalMassDiv
                          }
                        >
                          {({ getFieldValue }) => (
                            <>
                              <Form.Item
                                name={[name, 'optionLengthDiv']}
                                fieldKey={[fieldKey, 'optionLengthDiv']}
                                label="Lungime divizibila"
                              >
                                <DivisibleSelect
                                  disabled={
                                    !getFieldValue([
                                      'routeOptions',
                                      fieldKey,
                                      'optionTotalMassDiv',
                                    ])
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                name={[name, 'optionWidthDiv']}
                                fieldKey={[fieldKey, 'optionWidthDiv']}
                                label="Latime divizibila"
                              >
                                <DivisibleSelect
                                  disabled={
                                    !getFieldValue([
                                      'routeOptions',
                                      fieldKey,
                                      'optionTotalMassDiv',
                                    ])
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                name={[name, 'optionHeightDiv']}
                                fieldKey={[fieldKey, 'optionHeightDiv']}
                                label="Inaltime divizibila"
                              >
                                <DivisibleSelect
                                  disabled={
                                    !getFieldValue([
                                      'routeOptions',
                                      fieldKey,
                                      'optionTotalMassDiv',
                                    ])
                                  }
                                />
                              </Form.Item>
                            </>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row gutter={{ md: 32, lg: 64 }}>
                  {axisGroups.map(([from, to, indexes]) => (
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} key={from}>
                      <Divider plain>
                        Masa pe axe {from} - {to} (tone)
                      </Divider>
                      <Row gutter={16}>
                        {indexes.map((index) => (
                          <Col span={12} key={index}>
                            <Form.Item
                              name={[name, 'axisWeight', index]}
                              fieldKey={[fieldKey, 'axisWeight', index]}
                              label={`Axa ${index + 1}`}
                            >
                              <InputNumber
                                disabled={disabled}
                                min={0}
                                precision={2}
                                onChange={(val) =>
                                  handleAxisWeightChange(fieldKey, val)
                                }
                              />
                            </Form.Item>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}

            <Space>
              {!disabled && requestType !== 'PERMANENT' && (
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    const axisLength = form
                      .getFieldValue('axisOptions')
                      ?.reduce((a, b) => a + (b.distance || 0), 0);
                    add({
                      ...defaultRowValue,
                      oversized:
                        axisLength > dimensions.allowedLengthR &&
                        axisLength > dimensions.allowedLengthNR &&
                        axisLength > dimensions.allowedLengthM &&
                        axisLength > dimensions.allowedLengthP,
                    });
                  }}
                  icon={<PlusOutlined />}
                  disabled={
                    form.getFieldValue(`routeOptions`)?.length > 3 || disabled
                  }
                >
                  Adauga traseu
                </Button>
              )}
            </Space>
          </>
        )}
      </Form.List>
    </>
  );
};

export default RouteTab;
