import React from 'react';

import { Form, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import RequestTypeSelect from '../../../RequestTypeSelect';
import RouteTypeSelect from '../../../RouteTypeSelect';
import PeriodTypeSelect from '../../../PeriodTypeSelect';
import LocalDatePicker from '../../../LocalDatePicker';

const ValabilitySection = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider plain>Valabilitatea</Divider>
      <Form.Item name="requestType" label={t('entity.east.permit.requestType')}>
        <RequestTypeSelect disabled={disabled} />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => prev.requestType !== next.requestType}
      >
        {({ getFieldValue }) => (
          <>
            {getFieldValue('requestType') === 'PERMANENT' && (
              <>
                <Form.Item
                  name="routeType"
                  label={t('entity.east.permit.routeType')}
                >
                  <RouteTypeSelect disabled={disabled} />
                </Form.Item>
                <Form.Item
                  name="periodType"
                  label={t('entity.east.permit.periodType')}
                >
                  <PeriodTypeSelect disabled={disabled} />
                </Form.Item>
                <Form.Item
                  name="periodStartDate"
                  label={t('entity.east.permit.periodStartDate')}
                >
                  <LocalDatePicker
                    disabledDate={(current) => {
                      return current < moment().add(1, 'day').startOf('day');
                    }}
                    disabled={disabled}
                  />
                </Form.Item>
              </>
            )}
          </>
        )}
      </Form.Item>
    </>
  );
};

export default ValabilitySection;
