import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Divider } from 'antd';

import PhoneInput from 'react-phone-number-input';

import CountrySelect from '../../../CountrySelect';
import CountySelect from '../../../CountySelect';
import CitySelect from '../../../CitySelect';
import useFormContext from '../../../../hooks/useFormContext';

const TransportSection = ({ disabled, romaniaCountryCode }) => {
  const form = useFormContext();
  const { t } = useTranslation();

  const handleCountryChange = useCallback(() => {
    if (form.getFieldValue(['transportCountryId'])) {
      form.setFieldsValue({
        transportCityId: null,
        transportCountyId: null,
      });
    }
  }, [form]);

  const handleCountyChange = useCallback(() => {
    if (form.getFieldValue(['transportCountryId'])) {
      form.setFieldsValue({
        transportCityId: null,
      });
    }
  }, [form]);

  return (
    <>
      <Divider plain>{t('entity.east.permit.transport')}</Divider>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) =>
          (romaniaCountryCode &&
            prev.transportCountryId !== next.transportCountryId) ||
          prev.transportIsApplicant !== next.transportIsApplicant
        }
      >
        {({ getFieldValue }) => (
          <>
            <Form.Item
              name="transportPhone"
              label={t('entity.east.permit.transportPhone')}
            >
              <PhoneInput
                defaultCountry="RO"
                withCountryCallingCode
                disabled={disabled || getFieldValue('transportIsApplicant')}
              />
            </Form.Item>
            <Form.Item
              name="transportEmail"
              label={t('entity.east.permit.transportEmail')}
            >
              <Input
                disabled={disabled || getFieldValue('transportIsApplicant')}
              />
            </Form.Item>
            <Form.Item
              name="transportLegalName"
              label={t(
                `entity.east.permit.${
                  form.getFieldValue('isLegal')
                    ? 'transportLegalName'
                    : 'transportPersonName'
                }`,
              )}
            >
              <Input
                disabled={disabled || getFieldValue('transportIsApplicant')}
              />
            </Form.Item>
            <Form.Item
              name="transportCountryId"
              label={t('entity.east.permit.transportCountryId')}
            >
              <CountrySelect
                disabled={disabled || getFieldValue('transportIsApplicant')}
                onChange={handleCountryChange}
              />
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                romaniaCountryCode &&
                prev.transportCountryId !== next.transportCountryId
              }
            >
              {({ getFieldValue: getFieldValueNested }) =>
                getFieldValueNested('transportCountryId') ===
                romaniaCountryCode ? (
                  <>
                    <Form.Item
                      name="transportCountyId"
                      label={t('entity.east.permit.transportCountyId')}
                    >
                      <CountySelect
                        disabled={
                          disabled ||
                          getFieldValueNested('transportIsApplicant')
                        }
                        country={getFieldValueNested('transportCountryId')}
                        onSelect={handleCountyChange}
                      />
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        romaniaCountryCode &&
                        prev.transportCountyId !== next.transportCountyId
                      }
                    >
                      {({ getFieldValue: getFieldValueNested2 }) => (
                        <Form.Item
                          name="transportCityId"
                          label={t('entity.east.permit.transportCityId')}
                        >
                          <CitySelect
                            disabled={
                              disabled ||
                              getFieldValueNested2('transportIsApplicant')
                            }
                            county={getFieldValueNested('transportCountyId')}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    name="transportCity"
                    label={t('entity.east.permit.transportCityId')}
                  >
                    <Input
                      disabled={
                        disabled || getFieldValueNested('transportIsApplicant')
                      }
                    />
                  </Form.Item>
                )
              }
            </Form.Item>

            <Form.Item
              name="transportAddress"
              label={t('entity.east.permit.transportAddress')}
            >
              <Input
                disabled={disabled || getFieldValue('transportIsApplicant')}
              />
            </Form.Item>
            <Form.Item
              name="transportFiscalCode"
              label={t(
                `entity.east.permit.${
                  getFieldValue('transportCountryId') === romaniaCountryCode ||
                  getFieldValue('isLegal')
                    ? 'transportFiscalCode'
                    : 'transportVAT'
                }`,
              )}
            >
              <Input
                disabled={disabled || getFieldValue('transportIsApplicant')}
              />
            </Form.Item>
            {form.getFieldValue('isLegal') && (
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) =>
                  romaniaCountryCode &&
                  prev.transportCountryId !== next.transportCountryId
                }
              >
                {({ getFieldValue: getFieldValueNested }) => (
                  <>
                    {getFieldValueNested('transportCountryId') ===
                      romaniaCountryCode && (
                      <Form.Item
                        name="transportTradeRegisterCode"
                        label={t(
                          'entity.east.permit.transportTradeRegisterCode',
                        )}
                      >
                        <Input
                          disabled={
                            disabled || getFieldValue('transportIsApplicant')
                          }
                        />
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.Item>
            )}
          </>
        )}
      </Form.Item>
    </>
  );
};

export default TransportSection;
