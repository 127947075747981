import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Form, Input, InputNumber, Select } from 'antd';
import LocalDatePicker from '../LocalDatePicker';

const FORMATS = ['pdf', 'xlsx', 'csv'];

const statusMapping = [
  'NEW',
  'VEHICLE',
  'ROUTE',
  'ESCORT',
  'TERMS',
  'PAYMENT',
  'PAID',
  'CANCELLED',
  'DENIED',
];

const ReportWindow = ({ report, onPrint, onClose }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (report) {
      form.resetFields();
    }
  }, [form, report]);

  const renderInputControl = useCallback(
    (control) => (
      <Form.Item
        key={control.parameter}
        label={control.label}
        name={control.parameter}
      >
        {(() => {
          switch (control.type) {
            case 'numeric':
              return <InputNumber />;
            case 'datetime':
              return <LocalDatePicker />;
            case 'permit_status':
              return (
                <Select>
                  {statusMapping.map((row) => (
                    <Select.Option value={row} key={row}>
                      {t(`entity.east.permitStatus.${row}`)}
                    </Select.Option>
                  ))}
                </Select>
              );
            default:
              return <Input />;
          }
        })()}
      </Form.Item>
    ),
    [t],
  );

  return (
    <Modal
      title={report ? report.label : ''}
      visible={!!report}
      onOk={form.submit}
      onCancel={onClose}
      width={800}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ _format: FORMATS[0] }}
        onFinish={onPrint}
        autoComplete="off"
      >
        <Form.Item label="Format" name="_format">
          <Select>
            {FORMATS.map((format) => (
              <Select.Option key={format} value={format}>
                {t(`reports.format.${format}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {report && report.inputControls.map(renderInputControl)}
      </Form>
    </Modal>
  );
};

export default ReportWindow;
