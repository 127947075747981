import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import { Default } from '../../layouts';

import UsersList from '../../components/admin/users/UsersList';
import RolesList from '../../components/admin/users/RolesList';
import SubdivisionsList from '../../components/admin/users/SubdivisionsList';
import AuthContext, { hasPermission } from '../../components/auth';

const Users = () => {
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const { t } = useTranslation();
  return (
    <Default>
      <Tabs defaultActiveKey="users">
        <Tabs.TabPane tab={t('entity.admin.user._plural')} key="users">
          <UsersList />
        </Tabs.TabPane>
        {hasPermission(permissions, 'SYS_SUPERUSER') ? (
          <>
            <Tabs.TabPane tab={t('entity.admin.role._plural')} key="roles">
              <RolesList />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t('entity.admin.subdivision._plural')}
              key="subdivisions"
            >
              <SubdivisionsList />
            </Tabs.TabPane>
          </>
        ) : null}
      </Tabs>
    </Default>
  );
};

export default Users;
