import React from 'react';

import { useTranslation } from 'react-i18next';

import { Tooltip, Typography } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';

const ViewItemIcon = ({ path }) => {
  const { t } = useTranslation();

  return (
    <Tooltip placement="left" title={t('actions.view')}>
      <Typography.Link href={path} target="_blank">
        <QrcodeOutlined />
      </Typography.Link>
    </Tooltip>
  );
};

export default ViewItemIcon;
