import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { WalletOutlined } from '@ant-design/icons';
import { SERVICE_URI, BASE_URI } from '../../services/east/permits';

const DownloadInvoiceButton = ({ id }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="primary"
      icon={<WalletOutlined />}
      href={`${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/view/${id}/invoice`}
    >
      {t('actions.invoice')}
    </Button>
  );
};

export default DownloadInvoiceButton;
