import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Row, Col, Space, Radio, Checkbox, Alert } from 'antd';

import PhoneInput from 'react-phone-number-input';

import { FormContextProvider } from '../../hooks/useFormContext';
import useCountryCode from '../../hooks/useCountryCode';
import useFormErrors from '../../hooks/useFormErrors';
import FileInput from '../FileInput';
import SaveButton from '../buttons/SaveButton';
import CancelButton from '../buttons/BackButton';
import UserAdressForm from '../admin/users/UserAdressForm';

const initial = {
  legal: false,
};

const NaturalPersonFragment = ({ romaniaCountryCode }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item label={t('entity.admin.user.email')} name="email">
          <Input />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.password')} name="password">
          <Input type="password" />
        </Form.Item>
        <Alert
          message={t('entity.admin.user.passwordNotification')}
          type="warning"
        />
        <Form.Item
          label={t('entity.admin.user.repeatPassword')}
          name="repeatPassword"
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.fullname')} name="fullname">
          <Input />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.countryId !== next.countryId}
        >
          <>
            <Form.Item
              label={t(`entity.admin.user.personalCode`)}
              name="personalCode"
            >
              <Input />
            </Form.Item>
          </>
        </Form.Item>

        <Form.Item name="identityCard">
          <FileInput
            scope="usrdc"
            label={t('entity.admin.user.identityCard')}
          />
        </Form.Item>

        <UserAdressForm romaniaCountryCode={romaniaCountryCode} />

        <Form.Item label={t('entity.admin.user.phone')} name="phone">
          <PhoneInput defaultCountry="RO" withCountryCallingCode />
        </Form.Item>
      </Col>
    </Row>
  );
};

const LegalPersonFragment = ({ romaniaCountryCode }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item
          label={t('entity.admin.user.economicOperator')}
          name="legalFullName"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('entity.admin.user.fiscalCode')}
          name="personalCode"
        >
          <Input />
        </Form.Item>

        <Form.Item name="identityCard">
          <FileInput
            scope="usrdc"
            label={t('entity.admin.user.legalIdentityCard')}
          />
        </Form.Item>

        <Form.Item
          label={t('entity.admin.user.tradeRegisterCode')}
          name="tradeRegisterCode"
        >
          <Input />
        </Form.Item>

        <UserAdressForm romaniaCountryCode={romaniaCountryCode} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item label={t('entity.admin.user.email')} name="email">
          <Input />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.password')} name="password">
          <Input type="password" />
        </Form.Item>
        <Alert
          message={t('entity.admin.user.passwordNotification')}
          type="warning"
        />

        <Form.Item
          label={t('entity.admin.user.repeatPassword')}
          name="repeatPassword"
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.legalPhone')} name="phone">
          <PhoneInput defaultCountry="RO" withCountryCallingCode />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.bank')} name="bank">
          <Input />
        </Form.Item>

        <Form.Item label={t('entity.admin.user.iban')} name="iban">
          <Input />
        </Form.Item>

        <Form.Item
          label={t('entity.admin.user.fullnameLegalRep')}
          name="fullname"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('entity.admin.user.phoneLegalRep')}
          name="legalPhone"
        >
          <PhoneInput defaultCountry="RO" withCountryCallingCode />
        </Form.Item>
      </Col>
    </Row>
  );
};

const RegisterForm = ({ onSubmit, onCancel, errors }) => {
  const [form] = Form.useForm();
  useFormErrors(form, errors);

  const romaniaCountryCode = useCountryCode('ROM');

  const { t } = useTranslation();

  const legalOptions = useMemo(
    () => [
      { label: t('entity.admin.user.simplePerson'), value: false },
      { label: t('entity.admin.user.legalPerson'), value: true },
    ],
    [t],
  );

  return (
    <>
      <div
        style={{
          width: '100%',
          maxWidth: '960px',
          margin: '0 auto',
        }}
      >
        <FormContextProvider form={form}>
          <>
            <Form
              form={form}
              layout="vertical"
              initialValues={initial}
              onFinish={onSubmit}
            >
              <Form.Item name="legal">
                <Radio.Group
                  options={legalOptions}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => prev.legal !== next.legal}
              >
                {({ getFieldValue }) =>
                  getFieldValue('legal') ? (
                    <LegalPersonFragment
                      romaniaCountryCode={romaniaCountryCode}
                    />
                  ) : (
                    <NaturalPersonFragment
                      romaniaCountryCode={romaniaCountryCode}
                    />
                  )
                }
              </Form.Item>

              <Form.Item name="confirm" valuePropName="checked">
                <Checkbox>
                  {t('entity.admin.user.agreeWith')}
                  <a href="/terms" target="_blank">
                    {t('entity.admin.user.terms')}
                  </a>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Space>
                  <SaveButton />
                  <CancelButton onCancel={onCancel} />
                </Space>
              </Form.Item>
            </Form>
          </>
        </FormContextProvider>
      </div>
    </>
  );
};

export default RegisterForm;
