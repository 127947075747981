import React, { useMemo } from 'react';
import { Select } from 'antd';

import useDictionaries from '../hooks/useDictionaries';
import { findAll } from '../services/admin/geo/cities';

const CitySelect = ({ value, onSelect, county, ...rest }) => {
  const dictionaries = useMemo(
    () => ({
      cities: county
        ? (args) =>
            findAll({
              ...args,
              criterias: { county, ...(args.criterias || {}) },
            })
        : () => Promise.resolve({ totalItems: 0, content: [] }),
    }),
    [county],
  );

  const [{ cities }] = useDictionaries(dictionaries);
  const { loading, content } = cities;

  return (
    <Select
      {...rest}
      style={{ width: '100%' }}
      loading={loading}
      value={value}
      onSelect={onSelect}
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
      }
    >
      {content.map((row) => (
        <Select.Option value={row.id} key={row.id}>
          {row.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CitySelect;
