import React, { useCallback } from 'react';
// import { useTranslation } from 'react-i18next';

import { Form, Input, Row, Col, Space } from 'antd';

import { useTranslation } from 'react-i18next';

import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/BackButton';
import CountySelect from '../../CountySelect';
import CountrySelect from '../../CountrySelect';
import useFormErrors from '../../../hooks/useFormErrors';

const EditCityForm = ({ city, onSubmit, onCancel, errors }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  useFormErrors(form, errors);

  const handleCountryChange = useCallback(() => {
    if (form.getFieldValue(['countryId'])) {
      form.setFieldsValue({
        countyId: null,
      });
    }
  }, [form]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={city}
        onFinish={onSubmit}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
          <Col xs={24} sm={24} md={10} lg={8} xl={8}>
            <Form.Item label={t('entity.admin.city.code')} name="code">
              <Input />
            </Form.Item>
            <Form.Item label={t('entity.admin.city.name')} name="name">
              <Input />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.countryId !== next.countryId}
            >
              {({ getFieldValue }) => (
                <>
                  <Form.Item
                    label={t('entity.admin.country._singular')}
                    name="countryId"
                  >
                    <CountrySelect onChange={handleCountryChange} />
                  </Form.Item>
                  <Form.Item
                    label={t('entity.admin.county._singular')}
                    name="countyId"
                  >
                    <CountySelect country={getFieldValue('countryId')} />
                  </Form.Item>
                </>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <SaveButton />
            <CancelButton onCancel={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditCityForm;
