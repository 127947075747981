import React from 'react';

import { Typography } from 'antd';

import { Clean } from '../layouts';

const TermsAndConditions = () => {
  const { Title } = Typography;
  return (
    <Clean>
      <div
        style={{
          width: '100%',
          maxWidth: '960px',
          margin: '0 auto',
        }}
      >
        <Title level={3}>TERMENI SI CONDITII</Title>

        <p>
          Utilizarea acestui site implica acceptarea neconditionata a termenilor
          si conditiilor de mai jos.
        </p>
        <p>Recomandam citirea cu atentie a acestora</p>
        <p>
          C.N.A.I.R. S.A. isi asuma dreptul de a modifica/actualiza/corecta
          structura, continutul si serviciile in orice moment sau de a suspenda
          functionarea temporara a acestuia, fara o alta notificare.
        </p>
        <Title level={3}>
          1. CONDIŢII GENERALE PENTRU UTILIZAREA SITE-ULUI
        </Title>
        <p>
          Site-ul www.eAST.ro, numit pe parcursul acestor termeni si conditii
          eAST.ro, este proprietatea Companiei Nationale de Administrare a
          Infrastructurii Rutiere S.A. (C.N.A.I.R. S.A.), cu sediul in B-dul
          Dinicu Golescu nr. 38 Sector 1, Bucuresti, inregistrata la Registrul
          Comertului cu numarul J40/552/2004, CUI RO16054368.
        </p>
        <p>
          Prin intermediul site-ului eAST.ro se pot obtine Autorizatii Speciale
          de Transport (AST), pentru vehiculele care circula cu depasirea
          maselor si/sau dimensiunilor maxime admise in circulatie pe drumurile
          publice din Romania.
        </p>
        <p>
          Accesul/vizitarea eAST.ro se supune Termenilor si conditiilor de
          utilizare, implica acceptul explicit cu privire la acestia si
          reprezinta întreaga întelegere dintre parti. Clientul isi da acordul
          impunerii acestor termeni si conditii de utilizare. In situatia in
          care clientul nu este de acord cu acesti termeni, va accesa eAST.ro
          doar in scop informativ si nu in vederea obtinerii unei AST.
          C.N.A.I.R. S.A. isi rezerva dreptul de a modifica acesti termeni si
          conditii de utilizare in orice moment. Orice modificare a acestora va
          fi valabila de indata ce va fi postata pe eAST.ro.
        </p>
        <p>
          Continuarea accesarii si folosirii eAST.ro urmarind modificarea
          termenilor si conditiilor de utilizare constituie acceptarea acestora
          precum si a modificarilor survenite in timp.
        </p>
        <Title level={3}>2. DREPTURI DE PROPRIETATE INTELECTUALĂ </Title>
        <p>
          C.N.A.I.R. S.A. este deţinătoarea eAST.ro. Fotografiile, textele,
          sloganurile, desenele, imaginile, precum şi toate lucrările integrate
          în acest site sunt proprietatea C.N.A.I.R. S.A.
        </p>
        <Title level={3}>
          3. ACCESUL LA INFORMATIILE DISPONIBILE PE eAST.ro
        </Title>
        <p>
          C.N.A.I.R. S.A. ofera tuturor utilizatorilor accesul limitat, in
          interes profesional, pe eAST.ro si nu le confera dreptul de a modifica
          partial sau integral site-ul, de a reproduce partial sau integral
          site-ul, de a copia, de a vinde/ revinde sau de a exploata site-ul in
          orice alta maniera, in scopuri comerciale sau contrare intereselor
          C.N.A.I.R. S.A.
        </p>
        <p>
          Efectuarea de operatiuni neautorizate asupra acestui site si tentativa
          de efectuare a acestora, incluzând fara a se limita la: utilizarea
          abuziva, utilizarea frauduloasa, accesul neautorizat, modificarea,
          copierea de informatii în vederea utilizarii lor, blocarea accesului,
          etc, vor fi considerate tentative de fraudare a eAST.ro si va
          determina plangerea/cercetarea penala impotriva aceluia care a initiat
          aceasta actiune.
        </p>
        <p>
          Continutul eAST.ro nu poate fi copiat, reprodus, transferat, incarcat,
          publicat sau distribuit in orice mod fara a obtine in prealabil
          acordul scris al C.N.A.I.R S.A., exceptie facand posibilitatea de a
          tipari extrase din paginile acestuia doar pentru uz personal.
        </p>
        <Title level={3}>4. DEFINITII</Title>
        <p>
          Mase si dimensiuni maxime admise – masele si dimensiunile maxime
          admise ale vehiculelor, pe drumurile publice din Romania, asa cum sunt
          ele prevazute in anexele nr. 2 si 3 ale Ordonantei Guvernului nr.
          43/1997 privind regimul drumurilor, republicata, cu modificarile si
          completarile ulterioare.
        </p>
        <p>
          Autorizatie Speciala de Transport (AST) - documentul prin care
          administratorul drumului stabileşte traseul ce urmează a fi parcurs,
          condițiile de efectuare a transportului şi tarifele datorate, necesar
          pentru circulația vehiculelor rutiere cu depăşiri ale maselor maxime
          admise şi/sau ale dimensiunilor maxime admise, valabil pentru numerele
          de înmatriculare sau de înregistrare ale vehiculelor, pentru
          caracteristicile tehnice, traseul, sensul, perioada şi condiţiile de
          circulație impuse.
        </p>
        <p>
          Traseu autorizat - traseul impus de către administratorul drumului
          pentru deplasarea vehiculului cu depăşiri, stabilit în funcţie de
          originea şi destinaţia transportului, masele şi dimensiunile efective
          ale vehiculului, limitările impuse de portanţa drumului, de elementele
          infrastructurii drumurilor şi de restricţiile de circulaţie permanente
          şi temporare instituite.
        </p>
        <p>
          Vehicul cu depășiri - orice vehicul, cu sau fără încărcătură, ale
          cărui mase si/sau dimensiuni depăsesc masele si/sau dimensiunile
          maxime admise prevăzute în anexele nr. 2 si 3 la Ordonanta Guvernului
          nr. 43/1997 privind regimul drumurilor, republicată, cu modificările
          si completările ulterioare.
        </p>
        <p>
          Tarife suplimentare de utilizare - tarifele datorate de operatorul de
          transport pentru circulația vehiculelor cu depăsiri pe drumurile
          publice.
        </p>
        <Title level={3}>5. LIMITAREA RESPONSABILITĂŢII </Title>
        <p>
          Clientul foloseste eAST.ro pe riscul propriu, C.N.A.I.R. S.A. fiind
          libera de orice raspundere pentru eventualele daune directe sau
          indirecte, cauzate de utilizarea sau accesarea/vizitarea eAST.ro sau
          ca urmare a utilizarii informatiilor de pe site.
        </p>
        <p>
          C.N.A.I.R. S.A. nu este raspunzatoare de erorile sau omisiunile care
          pot interveni în urma furnizarii unor date incomplete sau incorecte de
          catre client.
        </p>
        <Title level={3}>6. CONFIDENTIALITATEA DATELOR</Title>
        <p>
          Toate documentele si informatiile de orice natura furnizate de catre
          clienti vor fi utilizate numai pentru realizarea scopului eAST.ro si
          anume eliberarea AST.
        </p>
        <p>
          C.N.A.I.R. S.A. este operator de date cu caracter personal si
          prelucrează datele de identificare comunicate de client în vederea
          îndeplinirii obligațiilor legale care îi revin, conform specificului
          activității derulate.
        </p>
        <p>
          Conform atribuțiilor legale, C.N.A.I.R. S.A. prelucrează datele cu
          caracter personal în scopul autorizării circulației vehiculelor cu
          depășiri ale maselor si/ sau dimensiunilor maxime admise prevăzute de
          OG nr. 43/1997 privind regimul drumurilor, republicata, cu
          modificările si completările ulterioare, respectiv emiterea și
          evidența AST.
        </p>
        <p>Datele cu caracter personal prelucrate de C.N.A.I.R. S.A. sunt:</p>
        <p>
          1. Nume, prenume, seria și numărul cartii de identitate/pașaportului -
          pentru identificarea persoanei desemnate să reprezinte clientul și
          anume persoana care completează pe proprie răspundere datele necesare
          pentru eliberarea AST;
        </p>
        <p>
          2. Codul numeric personal - în vederea întocmirii documentelor fiscale
          aferente autorizațiilor speciale de transport (factura);
        </p>
        <p>3. Adresa de email, număr de telefon/fax – pentru comunicare; </p>
        <p>Datele cu caracter personal sunt stocate pentru o perioadă de: </p>
        <p>
          a) 5 (cinci) ani pentru datele înscrise în cererile pentru eliberarea
          autorizațiilor speciale de transport și în registrul de evidență a
          autorizațiilor speciale de transport predate;
        </p>
        <p>
          b) 10 (zece) ani pentru datele înscrise în documentele fiscale
          (factură)
        </p>
        <p>
          Datele cu caracter personal prelucrate de C.N.A.I.R. S.A. pot fi puse
          la cerere, la dispoziția:
        </p>
        <p>
          a) autorităților guvernamentale și judecătorești și alte persoane
          juridice implicate în proceduri legale;
        </p>
        <p>
          b) organe de cercetare penală, autorități fiscale, precum și către
          alte persoane juridice față de care dezvăluirea este cerută de lege;
        </p>
        <p>
          c) persoane juridice cu competențe, potrivit legii, în derularea
          operațiunilor referitoare la recuperarea creanțelor;
        </p>
        <p>
          Prin acceptarea prezentei Note de informare vă exprimați în mod expres
          și neechivoc consimțământul pentru prelucrarea datelor dumneavoastră
          cu caracter personal, de către C.N.A.I.R. S.A.
        </p>
        <Title level={3}>
          7. INREGISTRARE/ CONT DE UTILIZATOR, PAROLE SI RESPONSABILITATI
        </Title>
        <p>
          Accesul clientului la facilitatile din cadrul eAST.ro necesita crearea
          unui cont de utilizator prin intermediul unei adrese de e-mail valide,
          a unei parole si a Codului Unic de Inregistrare la Oficiul Registrul
          Comertului, in cazul persoanelor juridice sau codului numeric personal
          in cazul persoanelor fizice.
        </p>
        <p>
          Inregistrarea este gratuita. Se pot inregistra numai persoanele
          juridice si persoanele fizice care detin capacitatea deplina de a
          incheia acte juridice. In special, se interzice inregistrarea
          minorilor.
        </p>
        <p>
          Informatiile pe care trebuie sa la furnizeze un client in timpul
          inregistrarii trebuie sa fie complete si corecte. Inregistrarea
          necesita o adresa de e-mail valida. Clientul trebuie sa aleaga o
          parola personala, confidentiala si care nu trebuie dezvaluita
          tertilor.
        </p>
        <p>
          C.N.A.I.R. S.A. nu poate fi responsabila pentru erorile survenite ca
          urmare a neglijentei clientului, in ceea ce priveste pastrarea
          confidentialitatii datelor de acces.
        </p>
        <p>
          Fiecare client se poate inregistra o singura data pe eAST.ro. Contul
          de utilizator nu este transferabil.
        </p>
        <p>
          C.N.A.I.R. S.A. nu va solicita niciodata parola conturilor
          dumneavoastra in mesaje transmise prin posta electronica sau telefon.
        </p>
        <p>
          Pentru activarea contului, clientul se va adresa subunitatilor
          C.N.A.I.R. S.A., conform instructiunilor primite prin e-mail si a
          informatiilor publicate pe site-ul C.N.A.I.R. S.A., la adresa de
          internet WWW.cnadnr.ro.
        </p>
        <p>
          C.N.A.I.R. S.A. isi rezerva dreptul de a nu activa conturile de
          utilizator in cazul in care clientul a furnizat date incomplete sau
          incorecte.
        </p>
        <p>
          C.N.A.I.R. S.A. isi rezerva dreptul de a inactiva conturile de
          utilizator neutilizate pentru o perioada mai lunga de timp sau in
          cazul in care clientul are, in mod repetat plati restante.
        </p>
        <Title level={3}>8. PLATA SI CONDITII DE PLATA </Title>
        <p>
          Clientul poate achita tarifele suplimentare de utilizare prin
          intermediul eAST.ro numai daca detine un cont de utilizator activ si
          un card bancar valid.
        </p>
        <p>
          Prin acceptarea platii pe eAST.ro clientul este de acord cu forma de
          comunicare prin care C.N.A.I.R. S.A. deruleaza operatiunile de
          incasare a tarifelor de utilizare.
        </p>
        <p>
          Dupa validarea comenzii, prin bifarea campului: “Plateste” clientul nu
          mai poate reveni asupra acesteia, comanda lansata fiind certa si
          exigibila, iar plata fiind obligatorie pentru acesta.
        </p>
        <p>
          Factura aferenta AST se va emite pe baza datelor furnizate de catre
          client, asa cum au fost introduse la crearea contului sau cum au fost
          actualizate/modificate in sectiunea specifica din eAST.ro.
        </p>
        <p>
          C.N.A.I.R. S.A. nu este responsabila si nu poate fi raspunzatoare
          pentru furnizarea de catre client a unor date incorecte, incomplete
          si/sau neadevarate.
        </p>
        <p>
          Recomandam clientilor sa verifice datele furnizate catre C.N.A.I.R.
          S.A., in baza carora se va emite factura aferenta comenzilor.
        </p>
        <p>
          Emiterea AST se va efectua numai dupa debitatrea din contul bancar al
          clientului a sumei aferente, iar data de inceput a valabilitatii AST
          nu poate fi anterioara acestei date.
        </p>
        <p>
          AST emise nu se pot anula, iar contravaloarea tarifelor aferente, nu
          se returneaza.
        </p>
        <Title level={3}>9. OBLIGATIILE CNAIR </Title>
        <p>
          C.N.A.I.R. S.A. va emite AST conform cu datele completate de catre
          client si numai in conditiile in care acestea se incadreaza in
          conditiile de emitere a AST si doar dupa confirmarea platii. Clientul
          va regasi factura si in contul creat pe eAST.ro. Clientul va primi pe
          adresa de e-mail cu care a deschis contul pe eAST.ro, din partea
          procesatorului de plati agreat de C.N.A.I.R. S.A. detalii privind
          plata efectuata.
        </p>
      </div>
    </Clean>
  );
};

export default TermsAndConditions;
