import { genericService, search } from '../../../helpers/api';

export const SERVICE_URI = '/taxonomies';
export const BASE_URI = '/v1/subdivisions';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const findAllActiveSubdivisions = () =>
  search(`${SERVICE_URI}${BASE_URI}`, { criterias: { deleted: false } });
