import React, { useEffect } from 'react';

import { Row, Col, Form } from 'antd';

import { FormContextProvider } from '../../hooks/useFormContext';

import EscortVehicleSection from './tabs/view/EscortVehicleSection';
import ApplicantFullSection from './tabs/view/ApplicantFullSection';
import OversizedVehicleSection from './tabs/view/OversizedVehicleSection';
import RoutesSection from './tabs/view/RoutesSection';
import IssuedDocSection from './tabs/view/IssuedDocSection';
import VehicleConfigurationTab from './tabs/view/VehicleConfigurationTab';
import QRCodeAndDocsSection from './tabs/view/QRCodeAndDocsSection';

const ViewPermitForm = ({ ident, permit }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(permit);
  }, [form, permit]);

  return (
    <>
      <FormContextProvider form={form}>
        <Form
          form={form}
          layout="vertical"
          initialValues={permit}
          onFinish={() => null}
        >
          <Row gutter={{ md: 32, lg: 64 }}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <QRCodeAndDocsSection />
              <IssuedDocSection />
              <ApplicantFullSection />
              <VehicleConfigurationTab ident={ident} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <OversizedVehicleSection />
              <EscortVehicleSection />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <RoutesSection />
            </Col>
          </Row>
        </Form>
      </FormContextProvider>
    </>
  );
};

export default ViewPermitForm;
